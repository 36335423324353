import React, { forwardRef, RefAttributes } from "react";
import { useTheme } from "styled-components";
import { ContentBlockHeader, TIconName } from "../../../";
import {
    StyledContainer,
    StyledItem,
    StyledItemDescription,
    StyledItemIcon,
    StyledItemLabel,
    StyledItemsContainer,
} from "./ContentBlockGrid.styled";

export interface IProps {
    subheadline?: string;
    headline: string;
    description?: string;
    columns?: number;
    backgroundColors?: string[];
    items?: {
        label?: string;
        description: string;
        iconName?: TIconName;
    }[];
}

export const ContentBlockGrid: React.FC<IProps & RefAttributes<HTMLElement>> =
    forwardRef(
        (
            {
                subheadline,
                headline,
                description,
                columns,
                backgroundColors,
                items,
            },
            ref,
        ) => {
            const theme = useTheme();

            return (
                <StyledContainer ref={ref} backgroundColors={backgroundColors}>
                    <ContentBlockHeader
                        subheadline={subheadline}
                        headline={headline}
                        description={description}
                    />

                    {items && (
                        <StyledItemsContainer>
                            {items.map(({ label, description, iconName }) => (
                                <StyledItem key={label} columnsCount={columns}>
                                    {iconName && (
                                        <StyledItemIcon
                                            name={iconName}
                                            color={theme.colors.accentSecondary}
                                            size={40}
                                        />
                                    )}

                                    <div>
                                        {label && (
                                            <StyledItemLabel
                                                level={3}
                                                alignment="left"
                                            >
                                                {label}
                                            </StyledItemLabel>
                                        )}

                                        <StyledItemDescription>
                                            {description}
                                        </StyledItemDescription>
                                    </div>
                                </StyledItem>
                            ))}
                        </StyledItemsContainer>
                    )}
                </StyledContainer>
            );
        },
    );
