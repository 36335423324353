import {
    EEventStates,
    EPaymentStates,
    IEvent,
    IOrder,
    IPayment,
    IPricesListItem,
    ITemplate,
} from "@gift/types";
import moment from "moment";

export const mockData: {
    template: ITemplate;
    event: IEvent;
    prices: IPricesListItem[];
    order: IOrder;
    payment: IPayment;
} = {
    template: {
        id: "sample-template-id",
        label: "Template",
        items: [
            {
                id: 1,
                label: "Item 1",
                description: "Description",
                category: "ACCOMMODATION",
                image: "placeholder.jpeg",
                quantity: 3,
                availableQuantity: 3,
                price: {
                    type: "PORTION",
                    value: 0.5,
                },
                isDefaultChecked: true,
            },
            {
                id: 2,
                label: "Item 2",
                description: "Description",
                category: "ACCOMMODATION",
                image: "placeholder.jpeg",
                quantity: 3,
                availableQuantity: 3,
                price: {
                    type: "FIXED",
                    values: {
                        CZK: 1000,
                    },
                },
                isDefaultChecked: false,
            },
        ],
    },
    event: {
        id: "sample-event-id",
        name: "Líbánky na Hawaii",
        description: "Popis",
        image: "placeholder.jpeg",
        amount: 120000,
        currencyCode: "CZK",
        dueDate: moment().toString(),
        durationDays: 7,
        bban: "1111/0100",
        templateId: "honeymoon",
        items: [
            {
                id: 1,
                label: "Item 1",
                description: "Description",
                category: "ACCOMMODATION",
                image: "placeholder.jpeg",
                quantity: 3,
                availableQuantity: 3,
                price: {
                    type: "PORTION",
                    value: 0.5,
                },
                isDefaultChecked: true,
            },
            {
                id: 2,
                label: "Item 2",
                description: "Description",
                category: "ACCOMMODATION",
                image: "placeholder.jpeg",
                quantity: 3,
                availableQuantity: 3,
                price: {
                    type: "FIXED",
                    values: {
                        CZK: 1000,
                    },
                },
                isDefaultChecked: false,
            },
        ],
        contributions: [],
        ownerId: "",
        state: EEventStates.CREATED,
        createdDate: moment().toString(),
        updatedDate: moment().toString(),
    },
    prices: [
        {
            id: 1,
            value: 1000,
        },
        {
            id: 2,
            value: 2000,
        },
    ],
    order: {
        items: [
            {
                id: 1,
                pricePerItem: 1000,
                quantity: 2,
            },
            {
                id: 2,
                pricePerItem: 2000,
                quantity: 1,
            },
        ],
        sums: {
            quantity: 3,
            price: 4000,
        },
    },
    payment: {
        id: 123456,
        state: EPaymentStates.SUCCESS,
        eventId: "sample-event-id",
        amount: 12000,
        currencyCode: "CZK",
        bban: "",
        iban: "",
        certificateData: {
            name: "Jan Chalupa",
            emailAddress: "jan.chalupa@brightworks.cz",
            message: "Message",
        },
        createdDate: moment().toString(),
        updatedDate: moment().toString(),
        isCertificatesSent: true,
        items: [
            {
                id: 1,
                quantity: 1,
                pricePerItem: 12000,
            },
            {
                id: 2,
                quantity: 1,
                pricePerItem: 12000,
            },
        ],
    },
};
