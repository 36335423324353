import { ICartItem, IEvent, IOrder, ITemplateItem } from "@gift/types";
import { Button, IModalRefProps, Text } from "@gift/ui";
import { calcTemplateItemsAmount, useTranslation } from "@gift/utils";
import { formatCurrency } from "@honzachalupa/utils";
import { useEffect, useMemo, useRef, useState } from "react";
import { Modal_EventItemCertificatePreview } from "../../../../../components/organisms/modals/EventItemCertificatePreview";
import {
    StyledAmountLabel,
    StyledContainer,
    StyledContent,
    StyledDescription,
    StyledImage,
    StyledInStockLabel,
    StyledItem,
    StyledLabel,
    StyledPlusMinusButton,
    StyledPlusMinusContainer,
    StyledPlusMinusLabel,
    StyledPreviewLink,
    StyledSumAmount,
    StyledSummaryContainer,
    StyledSummaryContent,
    StyledTitle,
} from "./Catalog.styled";
import { processCart } from "./Catalog.utils";

interface ICatalogProps {
    event: IEvent;
    onSubmit: (order: IOrder) => void;
}

export const Catalog: React.FC<ICatalogProps> = ({ event, onSubmit }) => {
    const t = useTranslation();

    const certificateModalRef = useRef<IModalRefProps>(null);

    const [cart, setCart] = useState<ICartItem[]>([]);
    const [order, setSummary] = useState<IOrder>();
    const [previewData, setPreviewData] = useState<ICartItem>();

    const { prices } = useMemo(
        () =>
            calcTemplateItemsAmount(
                event.items,
                event.amount,
                event.currencyCode,
            ),
        [event],
    );

    const handleUpdateItem = (
        id: ITemplateItem["id"],
        action: "add" | "remove",
    ) => {
        setCart((prevState) => {
            const existingItem = prevState?.find((item) => item.id === id);

            if (existingItem) {
                return prevState.map((item) =>
                    item.id === id
                        ? {
                              ...item,
                              quantity:
                                  action === "add"
                                      ? item.quantity + 1
                                      : item.quantity - 1,
                          }
                        : item,
                );
            } else if (action === "add") {
                return [
                    ...prevState,
                    {
                        id,
                        quantity: 1,
                        pricePerItem: -1,
                    },
                ];
            }

            return prevState;
        });
    };

    useEffect(() => {
        const { items, sums } = processCart(cart, event.items, event.amount);

        setSummary({
            items,
            sums,
        });
    }, [event, cart, prices]);

    return (
        <StyledContainer>
            {previewData && (
                <Modal_EventItemCertificatePreview
                    ref={certificateModalRef}
                    event={event}
                    item={previewData}
                />
            )}

            <StyledTitle level={2} alignment="left">
                {t("content_budget")}
            </StyledTitle>

            {event.items.map(
                ({
                    id,
                    label,
                    description,
                    image,
                    quantity,
                    availableQuantity,
                }) => {
                    const priceValue = prices.find(
                        (price) => price.id === id,
                    )!.value;

                    const chosenQuantity =
                        cart.find((cartItem) => cartItem.id === id)?.quantity ||
                        0;

                    return (
                        <StyledItem key={id}>
                            <StyledLabel>{`${label} - ${formatCurrency(
                                priceValue,
                                event.currencyCode,
                            )}`}</StyledLabel>

                            <StyledContent>
                                <StyledImage storagePath={image} />

                                <StyledDescription>
                                    {description}
                                </StyledDescription>

                                <StyledPlusMinusContainer>
                                    <StyledInStockLabel>
                                        <span>{t("content_inStock")}: </span>

                                        <span style={{ fontWeight: 600 }}>
                                            {availableQuantity - chosenQuantity}
                                        </span>

                                        <span> / {quantity}</span>
                                    </StyledInStockLabel>

                                    {(() => {
                                        const isDisabled = chosenQuantity === 0;

                                        return (
                                            <StyledPlusMinusButton
                                                label="Odebrat kus"
                                                icon={{
                                                    name: "minus",
                                                    color: isDisabled
                                                        ? "black"
                                                        : "white",
                                                }}
                                                color={
                                                    isDisabled
                                                        ? "grayLight"
                                                        : "red"
                                                }
                                                isDisabled={isDisabled}
                                                onClick={() =>
                                                    handleUpdateItem(
                                                        id,
                                                        "remove",
                                                    )
                                                }
                                            />
                                        );
                                    })()}

                                    <StyledPlusMinusLabel>
                                        {chosenQuantity}
                                    </StyledPlusMinusLabel>

                                    {(() => {
                                        const isDisabled =
                                            chosenQuantity ===
                                            availableQuantity;

                                        return (
                                            <StyledPlusMinusButton
                                                label="Přidat kus"
                                                icon={{
                                                    name: "plus",
                                                    color: isDisabled
                                                        ? "black"
                                                        : "white",
                                                }}
                                                color={
                                                    isDisabled
                                                        ? "grayLight"
                                                        : "green"
                                                }
                                                isDisabled={isDisabled}
                                                onClick={() =>
                                                    handleUpdateItem(id, "add")
                                                }
                                            />
                                        );
                                    })()}

                                    <StyledAmountLabel>
                                        {formatCurrency(
                                            priceValue * chosenQuantity,
                                            event.currencyCode,
                                        ) ||
                                            formatCurrency(
                                                0,
                                                event.currencyCode,
                                            )}
                                    </StyledAmountLabel>
                                </StyledPlusMinusContainer>

                                <StyledPreviewLink
                                    label={t(
                                        "buttonLabel_showCertificatePreview",
                                    )}
                                    size="small"
                                    color="grayDark"
                                    onClick={() => {
                                        setPreviewData({
                                            id,
                                            quantity: chosenQuantity || 1,
                                            pricePerItem: prices.find(
                                                (price) => price.id === id,
                                            )!.value,
                                        });

                                        setTimeout(() => {
                                            certificateModalRef.current?.show();
                                        }, 100);
                                    }}
                                />
                            </StyledContent>
                        </StyledItem>
                    );
                },
            )}

            <StyledSummaryContainer>
                <StyledSummaryContent>
                    <Text>{t("content_contributionAmountSum")}:</Text>

                    <StyledSumAmount>
                        {formatCurrency(
                            order?.sums.price || 0,
                            event.currencyCode,
                        )}
                    </StyledSumAmount>
                </StyledSummaryContent>

                <Button
                    label={t("buttonLabel_checkout")}
                    color="accentSecondary"
                    size="big"
                    isDisabled={!order?.sums.price}
                    onClick={() => onSubmit(order!)}
                />
            </StyledSummaryContainer>
        </StyledContainer>
    );
};
