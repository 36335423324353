import { Storage } from "@gift/utils";
import { useEffect, useState } from "react";
import { StyledContainer, StyledImage } from "./ImageUploaderGallery.styled";

export interface IGalleryImage {
    url: string;
    path: string;
}

export interface IProps {
    templateId: string;
    onChange: (data: IGalleryImage) => void;
}

export const ImageUploaderGallery: React.FC<IProps> = ({
    templateId,
    onChange,
}) => {
    const [imagesPath, setImagesPath] = useState<string[]>([]);

    useEffect(() => {
        Storage.search(`/event-gallery-images/${templateId}`).then((paths) => {
            setImagesPath(paths);
        });
    }, [templateId]);

    return (
        <StyledContainer>
            {imagesPath.map((path) => (
                <StyledImage
                    key={path}
                    storagePath={path}
                    onClick={(url) =>
                        onChange({
                            url,
                            path,
                        })
                    }
                />
            ))}
        </StyledContainer>
    );
};
