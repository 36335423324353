import { IModalRefProps, Modal as ModalCore } from "@honzachalupa/ui";
import { forwardRef, ReactNode, RefAttributes } from "react";

export interface IProps {
    width?: number;
    height?: number;
    children: ReactNode;
    onClose?: () => void;
}

export const Modal: React.FC<IProps & RefAttributes<IModalRefProps>> =
    forwardRef((props, ref) => (
        <ModalCore ref={ref} {...props} closeButtonText="Zavřít" />
    ));
