import { IFormInput, ITemplateItem } from "@gift/types";
import { Form } from "@gift/ui";
import { useTranslation } from "@gift/utils";
import { TCurrencyCodes } from "@honzachalupa/utils";
import { useMemo } from "react";

export interface IFormData {
    label: string;
    description: string;
    quantity: number;
    price: number;
}

export interface IProps {
    currencyCode: TCurrencyCodes;
    onSubmit: (item: ITemplateItem) => void;
}

export const Form_CreateTemplateItem: React.FC<IProps> = ({
    currencyCode,
    onSubmit,
}) => {
    const t = useTranslation();

    const handleSubmit = ({
        label,
        description,
        quantity,
        price,
    }: IFormData) => {
        onSubmit({
            id: Math.random(),
            label,
            description: description || "",
            image: "template-item-images/custom.jpeg",
            category: "CUSTOM",
            quantity,
            availableQuantity: quantity,
            price: {
                type: "FIXED",
                values: {
                    [currencyCode]: price,
                },
            },
            isDefaultChecked: true,
        });
    };

    return (
        <Form
            inputs={useMemo<IFormInput[]>(
                () => [
                    {
                        id: "label",
                        label: "Název",
                        controlType: "input",
                        rules: {
                            required: true,
                        },
                    },
                    {
                        id: "description",
                        label: "Popis",
                        controlType: "input",
                    },
                    {
                        id: "quantity",
                        label: "Počet",
                        controlType: "input",
                        inputType: "number",
                        rules: {
                            required: true,
                        },
                        containerStyle: {
                            width: "49%",
                        },
                    },
                    {
                        id: "price",
                        label: "Cena za kus",
                        controlType: "input",
                        inputType: "number",
                        rules: {
                            required: true,
                        },
                        containerStyle: {
                            width: "49%",
                            marginLeft: "2%",
                        },
                    },
                ],
                [t],
            )}
            buttonsRenderer={(submit) => [
                {
                    label: "Přidat",
                    onClick: submit,
                },
            ]}
            onSubmit={handleSubmit}
        />
    );
};
