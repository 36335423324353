import { DevBar, IModalRefProps, Layout, MockAction, Title } from "@gift/ui";
import { EventActions, log, useCurrentPage, useTranslation } from "@gift/utils";
import { DATE_FORMAT } from "@honzachalupa/utils";
import moment from "moment";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import {
    Form_EventCreate_Step1,
    IEventCreateStep1FormData,
} from "../../../components/organisms/forms/EventCreate_Step1";
import {
    Form_EventCreate_Step2,
    IEventCreateStep2FormData,
} from "../../../components/organisms/forms/EventCreate_Step2";
import { Modal_SignUp } from "../../../components/organisms/modals/SignUp";

type IFormData = IEventCreateStep1FormData & IEventCreateStep2FormData;

const StyledStepContainer = styled.div<{ isVisible: boolean }>`
    display: ${({ isVisible }) => (isVisible ? "" : "none")};
`;

export const Page_EventCreate: React.FC = () => {
    const t = useTranslation();
    const {
        isUserAuthenticated,
        location: { state: routeState },
        navigateTo,
    } = useCurrentPage({
        title: t("headline_createProject"),
    });

    const [formData_step1, setFormData_Step1] =
        useState<IEventCreateStep1FormData>();
    const [step, setStep] = useState<0 | 1>(0);
    const [createEventCallback, setCreateEventCallback] = useState<
        () => () => void
    >(() => () => {});

    const signUpModalRef = useRef<IModalRefProps>(null);

    const eventId = EventActions.createId();

    const createEvent = (formData: IFormData) => {
        EventActions.create(eventId, formData)
            .then(() => navigateTo.eventSummary(eventId))
            .catch((error) => {
                log({
                    code: "EXCEPTION",
                    scope: Page_EventCreate.name,
                    error,
                });
            });
    };

    const handleSubmit2Step = (formData_step2: IEventCreateStep2FormData) => {
        const formData = {
            ...formData_step1,
            ...formData_step2,
        } as IFormData;

        const createEventCallback = () =>
            createEvent({
                ...formData,
                dueDate: moment(formData.dueDate, DATE_FORMAT).toString(),
            });

        if (isUserAuthenticated) {
            createEventCallback();
        } else {
            // Needs to be wrapped in two arrow functions to avoid immediate call
            setCreateEventCallback(() => () => createEventCallback());

            signUpModalRef.current?.show();
        }
    };

    return (
        <Layout width="narrow">
            <DevBar>
                <MockAction
                    name="createEvent"
                    setFormData={handleSubmit2Step}
                />
            </DevBar>

            <Modal_SignUp
                ref={signUpModalRef}
                handleCreateEvent={createEventCallback}
            />

            <StyledStepContainer isVisible={step === 0}>
                <Title level={1}>{t("headline_createProject_step1")}</Title>

                <Form_EventCreate_Step1
                    onSubmit={(formData) => {
                        setFormData_Step1(formData);
                        setStep(1);
                    }}
                />
            </StyledStepContainer>

            <StyledStepContainer isVisible={step === 1}>
                <Title level={1}>{t("headline_createProject_step2")}</Title>

                {formData_step1?.templateId && (
                    <Form_EventCreate_Step2
                        defaultEventName={routeState?.defaultEventName}
                        eventId={eventId}
                        templateId={formData_step1.templateId}
                        goBack={() => setStep(0)}
                        onSubmit={handleSubmit2Step}
                    />
                )}
            </StyledStepContainer>
        </Layout>
    );
};
