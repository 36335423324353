import { IContext } from "@gift/types";
import {
    Context,
    useAuthentication,
    useNavigation,
    useTranslation,
} from "@gift/utils";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { Button } from "../../../../../../";
import { INavigationItems } from "../../Navigation";
import {
    StyledButtonsContainer,
    StyledButtonsGroup,
    StyledContainer,
    StyledIcon,
    StyledItemsContainer,
    StyledNavigationItem,
    StyledOverlay,
    StyledSignOutLink,
} from "./Mobile.styled";

interface IProps {
    items: INavigationItems;
}

export const View_Mobile: React.FC<IProps> = ({ items }) => {
    const { isUserAuthenticated, isAdmin } = useAuthentication();
    const t = useTranslation();
    const theme = useTheme();
    const navigateTo = useNavigation();

    const { isScrollDisabled, setIsScrollDisabled } =
        useContext<IContext>(Context);

    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    useEffect(() => {
        if (isExpanded !== isScrollDisabled) {
            setIsScrollDisabled(isExpanded);
        }
    }, [isScrollDisabled, isExpanded]);

    return (
        <StyledContainer>
            <StyledButtonsContainer isHidden={isExpanded}>
                <StyledIcon
                    label="Menu"
                    name="hamburger"
                    color={theme.colors.accentSecondary}
                    onClick={() => setIsExpanded(true)}
                />

                <StyledIcon
                    label={items.top.account.label}
                    name="persons"
                    color={theme.colors.accentSecondary}
                    onClick={() => navigateTo.path(items.top.account.path!)}
                />
            </StyledButtonsContainer>

            {isExpanded && (
                <Fragment>
                    <StyledOverlay />

                    <StyledItemsContainer>
                        <StyledButtonsContainer alignment="right">
                            <StyledIcon
                                label="Zavřít menu"
                                name="cross"
                                color={theme.colors.grayDark}
                                onClick={() => setIsExpanded(false)}
                            />
                        </StyledButtonsContainer>

                        {items.bottom.map(({ label, path, onClick }) => (
                            <StyledNavigationItem
                                key={label}
                                label={label}
                                path={path}
                                onClick={
                                    onClick
                                        ? () => {
                                              onClick();
                                              setIsExpanded(false);
                                          }
                                        : undefined
                                }
                            />
                        ))}

                        <StyledButtonsGroup orientation="vertical">
                            <Button
                                label={t("buttonLabel_createProject")}
                                color="accentSecondary"
                                onClick={navigateTo.eventCreate}
                            />

                            {isAdmin && (
                                <Button
                                    label="Administrace"
                                    color="grayLight"
                                    onClick={navigateTo.administration}
                                />
                            )}
                        </StyledButtonsGroup>

                        {isUserAuthenticated && (
                            <StyledSignOutLink
                                label={items.top.signOut.label}
                                onClick={() => {
                                    items.top.signOut.onClick?.();

                                    setIsExpanded(false);
                                }}
                            />
                        )}
                    </StyledItemsContainer>
                </Fragment>
            )}
        </StyledContainer>
    );
};
