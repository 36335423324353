export enum EEventStates {
    CREATING = "CREATING",
    CREATED = "CREATED",
    GOAL_AMOUNT_REACHED = "GOAL_AMOUNT_REACHED",
    ARCHIVED_BY_USER = "ARCHIVED_BY_USER",
    ARCHIVED_BY_SYSTEM = "ARCHIVED_BY_SYSTEM",
}

export enum EEventStateLabels {
    CREATING = "Vytváří se",
    CREATED = "Vytvořeno",
    GOAL_AMOUNT_REACHED = "Cílová částka dosažena",
    ARCHIVED_BY_USER = "Ukončeno uživatelem",
    ARCHIVED_BY_SYSTEM = "Událost již proběhla",
}
