import { IContext } from "@gift/types";
import { ContentBlockGrid } from "@gift/ui";
import { Context } from "@gift/utils";
import { useContext, useEffect, useRef } from "react";

export const ContentBlock_About: React.FC = () => {
    const ref = useRef<HTMLElement>(null);

    const { setNavigationRef } = useContext<IContext>(Context);

    useEffect(() => {
        setNavigationRef("aboutUs", ref);
    }, []);

    return (
        <ContentBlockGrid
            ref={ref}
            subheadline="O nás"
            headline="Pomáháme měnit peníze na splněné sny novomanželů"
            description="„Milujeme svatby. Tolik radosti, napětí a zábavy v jediný den se dá zažít málokde. Všimli jsme si, že novomanželům i hostům přidělávají starosti svatební dary. Ženich s nevěstou by nejvíc ocenili peníze na úhradu líbánek, ale neví, jak si o ně říct. Místo toho dostanou plno žertovných dárků, pro které nemají prostor ani využití. Proto jsme vymysleli DárekNaSvatbu.cz"
            backgroundColors={["#E4E0EB", "#EFF5F7", "#f6f3ea"]}
        />
    );
};
