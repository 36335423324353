import { IFormInput } from "@gift/types";
import {
    Button,
    ButtonsGroup,
    Form,
    IModalRefProps,
    Link,
    Loader,
} from "@gift/ui";
import { Authentication, useNavigation, useTranslation } from "@gift/utils";
import { Fragment, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { Modal_PasswordReset } from "../modals/PasswordReset";

interface IFormData {
    emailAddress: string;
    password: string;
}

const StyledSignUpButtonsGroup = styled(ButtonsGroup)`
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        display: none;
    }
`;

export const Form_SignIn: React.FC = () => {
    const t = useTranslation();
    const navigateTo = useNavigation();

    const resetPasswordModalRef = useRef<IModalRefProps>(null);

    const [formData, setFormData] = useState<IFormData>();
    const [isLoading, setIsLoading] = useState<boolean>();
    const [errorCode, setErrorCode] = useState<string>();

    const errorMessage = useMemo(
        () => (errorCode ? t(`errorMessage_${errorCode}`) : null),
        [errorCode],
    );

    const handleSubmit = ({ emailAddress, password }: IFormData) => {
        setIsLoading(true);

        Authentication.signInWithEmailAndPassword(emailAddress, password)
            .then(() => navigateTo.home())
            .catch((error) => {
                setErrorCode(error.code);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <Fragment>
            <Modal_PasswordReset
                ref={resetPasswordModalRef}
                emailAddress={formData?.emailAddress}
                onSubmit={() => {
                    resetPasswordModalRef.current?.hide();

                    alert("E-mail s instrukcemi byl odeslán.");
                }}
            />

            <Form
                inputs={useMemo<IFormInput[]>(
                    () => [
                        {
                            id: "emailAddress",
                            label: t("inputLabel_emailAddress"),
                            controlType: "input",
                            rules: {
                                required: true,
                            },
                        },
                        {
                            id: "password",
                            label: t("inputLabel_password"),
                            controlType: "input",
                            inputType: "password",
                            rules: {
                                required: true,
                            },
                            error: errorMessage,
                        },
                        {
                            id: "password-reset",
                            controlType: "custom",
                            children: (
                                <Link
                                    label="Zapomenuté heslo"
                                    size="small"
                                    onClick={() =>
                                        resetPasswordModalRef.current?.show()
                                    }
                                />
                            ),
                        },
                    ],
                    [t, errorMessage],
                )}
                buttonsRenderer={(submit) => [
                    {
                        label: t("buttonLabel_signIn"),
                        onClick: submit,
                    },
                ]}
                onChange={setFormData}
                onSubmit={handleSubmit}
            />

            <StyledSignUpButtonsGroup>
                <Button
                    label={t("buttonLabel_signUp")}
                    color="grayLight"
                    onClick={navigateTo.signUp}
                />
            </StyledSignUpButtonsGroup>

            {isLoading && <Loader isFullscreen />}
        </Fragment>
    );
};
