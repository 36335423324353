import { IEvent, IFormInput } from "@gift/types";
import {
    Form,
    IImageUploaderRefProps,
    ImageUploader,
    StyledErrorMessage,
} from "@gift/ui";
import { useTranslation } from "@gift/utils";
import { DATE_FORMAT } from "@honzachalupa/utils";
import moment from "moment";
import { Fragment, useMemo, useRef, useState } from "react";
import { useTheme } from "styled-components";

export interface IEventCreateStep2FormData {
    name: IEvent["name"];
    dueDate: IEvent["dueDate"];
    description: IEvent["description"];
    image: any;
    bban: IEvent["bban"];
}

export interface IProps {
    defaultEventName: string;
    eventId: string;
    templateId: string;
    goBack: () => void;
    onSubmit: (formData: IEventCreateStep2FormData) => void;
}

export const Form_EventCreate_Step2: React.FC<IProps> = ({
    defaultEventName,
    eventId,
    templateId,
    goBack,
    onSubmit,
}) => {
    const theme = useTheme();
    const t = useTranslation();

    const [imageUploaderError, setImageUploaderError] = useState<
        string | null
    >();

    const imageUploaderRef = useRef<IImageUploaderRefProps>(null);
    const formRef = useRef<HTMLFormElement>(null);

    const validateDueDate = (value: string) => {
        if (moment(value, DATE_FORMAT).diff(moment()) < 0) {
            return "Datum je v minulosti.";
        }

        if (moment(value, DATE_FORMAT).diff(moment().add(1, "year")) > 0) {
            return "Termín může být nejpozději za rok.";
        }

        return undefined;
    };

    const handleSubmit = async ({
        name,
        description,
        dueDate,
        bban,
    }: IEventCreateStep2FormData) => {
        const { status, imagePath } = imageUploaderRef.current!.getImagePath();

        if (status === "success") {
            setImageUploaderError(null);

            onSubmit({
                name,
                description,
                image: imagePath,
                dueDate,
                bban,
            });
        } else {
            setImageUploaderError("Zvolte prosím obrázek.");
        }
    };

    return (
        <Form
            ref={formRef}
            inputs={useMemo<IFormInput[]>(
                () => [
                    {
                        id: "name",
                        label: t("inputLabel_projectName"),
                        value: defaultEventName,
                        controlType: "input",
                        placeholder: t("inputPlaceholder_projectName"),
                        rules: {
                            required: true,
                        },
                    },
                    {
                        id: "dueDate",
                        label: t("inputLabel_dueDate"),
                        placeholder: t("inputPlaceholder_dueDate"),
                        controlType: "input",
                        inputType: "date",
                        rules: {
                            required: true,
                            validate: (value) =>
                                validateDueDate(value as string),
                        },
                    },
                    {
                        id: "bban",
                        label: t("inputLabel_bankAccountNumber"),
                        placeholder: t("inputPlaceholder_bankAccountNumber"),
                        controlType: "input",
                        rules: {
                            required: true,
                            pattern: /^\d+\/\d{4}$/,
                        },
                    },
                    {
                        id: "image-uploader",
                        label: t("inputLabel_image"),
                        controlType: "custom",
                        children: (
                            <Fragment>
                                <ImageUploader
                                    ref={imageUploaderRef}
                                    eventId={eventId}
                                    templateId={templateId}
                                    onChange={() => setImageUploaderError(null)}
                                />

                                {imageUploaderError && (
                                    <StyledErrorMessage>
                                        {imageUploaderError}
                                    </StyledErrorMessage>
                                )}
                            </Fragment>
                        ),
                    },
                    {
                        id: "description",
                        label: t("inputLabel_description"),
                        controlType: "textarea",
                    },
                ],
                [t, theme, defaultEventName, imageUploaderError],
            )}
            onSubmit={handleSubmit}
            buttonsRenderer={(submit) => [
                {
                    label: t("buttonLabel_back"),
                    color: "grayLight",
                    type: "button",
                    onClick: goBack,
                },
                {
                    label: t("buttonLabel_create"),
                    onClick: submit,
                },
            ]}
        />
    );
};
