export enum ERoutePaths {
    HOME = "/",
    EVENT_CREATE = "/napsat-prani",
    EVENT_SUMMARY = "/shrnuti/:id",
    EVENT_EDIT = "/prani/:id",
    EVENT_DETAIL = "/prispeji/:id",
    EVENT_CHECKOUT = "/prispeji/:id/souhrn",
    PAYMENT_CONFIRMATION = "/potvrzeni-platby",
    PAYMENT_CERTIFICATES_DOWNLOAD = "/stazeni-certifikatu/:id",
    ACCOUNT = "/muj-ucet",
    AUTHENTICATION_SIGN_UP = "/registrace",
    AUTHENTICATION_SIGN_IN = "/prihlaseni",
    CONTACT_US = "/kontaktujte-nas",
    NOT_FOUND = "/stranka-nenalezena",
    ADMINISTRATION = "/administrace",
    ADMINISTRATION_INVOICES = "/administrace/faktury",
    TERMS_AND_CONDITIONS = "/darovaci-podminky",
    PERSONAL_DATA_PROTECTION = "/ochrana-osobnich-udaju",
}

export const privatePaths = [
    ERoutePaths.EVENT_SUMMARY,
    ERoutePaths.EVENT_EDIT,
    ERoutePaths.ACCOUNT,
];

export const adminPaths = [ERoutePaths.ADMINISTRATION];
