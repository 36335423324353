import { ERoutePaths } from "@gift/types";
import { Route } from "react-router-dom";
import { Page_EventCheckout } from "./Checkout";
import { Page_EventCreate } from "./Create";
import { Page_EventDetail } from "./Detail";
import { Page_EventEdit } from "./Edit";
import { Page_EventSummary } from "./Summary";

export default [
    <Route path={ERoutePaths.EVENT_CREATE} element={<Page_EventCreate />} />,
    <Route path={ERoutePaths.EVENT_SUMMARY} element={<Page_EventSummary />} />,
    <Route path={ERoutePaths.EVENT_EDIT} element={<Page_EventEdit />} />,
    <Route path={ERoutePaths.EVENT_DETAIL} element={<Page_EventDetail />} />,
    <Route
        path={ERoutePaths.EVENT_CHECKOUT}
        element={<Page_EventCheckout />}
    />,
];
