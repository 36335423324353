import { Storage } from "@gift/utils";
import { Image as ImageCore } from "@honzachalupa/ui";

export interface IProps {
    storagePath: string;
    className?: string;
    onClick?: (imageUrl: string) => void;
}

export const Image: React.FC<IProps> = ({
    storagePath,
    className,
    onClick,
}) => (
    <ImageCore
        storagePath={storagePath}
        fetchFailedText="Obrázek se nepodařilo načíst"
        className={className}
        fetchStorageImage={Storage.download}
        onClick={onClick}
    />
);
