import { IEvent, IOrder } from "@gift/types";
import {
    IModalRefProps,
    Modal,
    PdfDocumentPreview,
    TemplateItemCertificateDocument,
} from "@gift/ui";
import { forwardRef, RefAttributes } from "react";

export interface IProps {
    event: IEvent;
    item: IOrder["items"][0];
}

export const Modal_EventItemCertificatePreview: React.FC<
    IProps & RefAttributes<IModalRefProps>
> = forwardRef(({ event, item }, ref) => (
    <Modal ref={ref}>
        <PdfDocumentPreview
            document={
                <TemplateItemCertificateDocument
                    event={event}
                    item={item}
                    formData={{
                        name: "{{Vaše jméno}}",
                        message: "{{Vaše zpráva}}",
                        emailAddress: "",
                    }}
                />
            }
        />
    </Modal>
));
