import { useTranslation } from "@gift/utils";
import { formatPhoneNumber } from "@honzachalupa/utils";
import {
    StyledContainer,
    StyledContainerRight,
    StyledLabel,
    StyledName,
} from "./Author.styled";

interface IProps {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    emailAddress: string;
}

export const Author: React.FC<IProps> = ({
    firstName,
    lastName,
    phoneNumber,
    emailAddress,
}) => {
    const t = useTranslation();

    return (
        <StyledContainer>
            <div>
                <StyledName>
                    {firstName} {lastName}
                </StyledName>

                <StyledLabel>{t("content_projectOwner")}</StyledLabel>
            </div>

            <StyledContainerRight>
                <StyledLabel>
                    {formatPhoneNumber(`+420${phoneNumber}`)}
                </StyledLabel>

                <StyledLabel>{emailAddress}</StyledLabel>
            </StyledContainerRight>
        </StyledContainer>
    );
};
