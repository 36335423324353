import { useNavigation } from "@gift/utils";
import { StyledButton } from "./NavigationItem.styled";

export interface INavigationItemProps {
    label: string;
    path?: string;
    isSelected?: boolean;
    className?: string;
    onClick?: () => void;
}

export const NavigationItem: React.FC<INavigationItemProps> = ({
    label,
    path,
    isSelected = false,
    className,
    onClick,
}) => {
    const navigateTo = useNavigation();

    const handleClick = () => {
        if (path) {
            navigateTo.path(path);
        } else if (onClick) {
            onClick();
        }
    };

    return (
        <StyledButton
            onClick={handleClick}
            isSelected={isSelected}
            className={className}
        >
            {label}
        </StyledButton>
    );
};
