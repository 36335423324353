import { EEventStates, IEvent, ITemplate, ITemplateItem } from "@gift/types";
import {
    convertDocsToItems,
    convertDocToItem,
    Firestore,
    TQuery,
} from "@honzachalupa/firebase";
import { TCurrencyCodes } from "@honzachalupa/utils";
import { Authentication, Collections, Database } from "../helpers/firebase";
import { log } from "../helpers/logging";

const update = (id: IEvent["id"], event: Partial<IEvent>) =>
    Database.update(Collections.events, id, event);

export const EventActions = {
    createId: () => Firestore.doc(Collections.events).id,

    /* const id = eventName
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/[-_+]+/g, " ")
            .replace(
                /(\w)(\w*)/g,
                (_, g1, g2) => g1.toUpperCase() + g2.toLowerCase(),
            )
            .replace(/\s+/g, "");

        return get(id)
            .then((event: IEvent) => {
                return Object.keys(event).length > 1
                    ? id + Math.round(Math.random() * 1000)
                    : id;
            })
            .catch((error) => {
                log({
                    code: "EXCEPTION",
                    scope: "EventActions",
                    error,
                });

                return id + Math.round(Math.random() * 1000);
            }); */

    create: (
        id: IEvent["id"],
        event: {
            name: string;
            description: string;
            image: string;
            amount: number;
            currencyCode: TCurrencyCodes;
            dueDate: string;
            durationDays: number;
            bban: string;
            templateId: ITemplate["id"];
            items: ITemplateItem[];
        },
    ) =>
        Database.set(Collections.events, id, {
            ...event,
            ownerId: Authentication.getCurrentUser()?.uid,
        }),
    update,

    search: (query?: TQuery): Promise<IEvent[]> =>
        Database.search(Collections.events, query)
            .then(convertDocsToItems)
            .catch((error) => {
                log({
                    code: "EXCEPTION",
                    scope: "EventActions",
                    error,
                });

                return [];
            }),

    get: (id: IEvent["id"]): Promise<IEvent> =>
        Database.get(Collections.events, id)
            .then((doc) => {
                // TODO: Fix situations when doc doesn't exists and returns "id" only
                if (Object.keys(convertDocToItem(doc)).join() === "id") {
                    return null;
                }

                return convertDocToItem(doc);
            })
            .catch((error) => error),

    getAsync: (
        id: IEvent["id"],
        callback: (event: IEvent) => void,
        callbackError?: (error: Error) => void,
    ) =>
        Database.getAsync(
            Collections.events,
            id,
            (doc) => {
                callback(convertDocToItem(doc) as IEvent);
            },
            callbackError,
        ),

    archive: (eventId: IEvent["id"]) =>
        update(eventId, {
            state: EEventStates.ARCHIVED_BY_USER,
        }),
};
