import { EInvoiceStates, IInvoice } from "@gift/types";
import { convertDocsToItems, TQuery } from "@honzachalupa/firebase";
import { Collections, Database } from "../helpers/firebase";
import { log } from "../helpers/logging";

export const InvoicesActions = {
    search: (query?: TQuery) =>
        Database.search(Collections.invoices, query)
            .then(convertDocsToItems)
            .catch((error) => {
                log({
                    code: "EXCEPTION",
                    scope: "InvoicesActions",
                    error,
                });

                return [];
            }) as Promise<IInvoice[]>,

    searchAsync: (callback: (invoice: IInvoice[]) => void) =>
        Database.searchAsync(Collections.invoices, (docs) => {
            callback(convertDocsToItems(docs) as IInvoice[]);
        }),

    setState: (id: IInvoice["id"], state: EInvoiceStates) =>
        Database.update(Collections.invoices, id, { state }),
};
