import { IConfigTemplate, ITemplate } from "@gift/types";

export const fillTranslations = (
    templates: IConfigTemplate[],
    t: (key: string) => string,
) =>
    templates.map(
        ({ labelTranslationKey, items, ...rest }) =>
            ({
                ...rest,
                label: t(labelTranslationKey),
                items: items.map(
                    ({
                        labelTranslationKey,
                        descriptionTranslationKey,
                        ...rest
                    }) => ({
                        ...rest,
                        label: t(labelTranslationKey),
                        description: descriptionTranslationKey
                            ? t(descriptionTranslationKey)
                            : undefined,
                    }),
                ),
            } as ITemplate),
    );
