import styled from "styled-components";
import { Image } from "../Image";

export const StyledContainer = styled.div`
    width: 100%;
    height: 150px;
    display: flex;
    margin-top: 10px;
    overflow-x: scroll;
`;

export const StyledImage = styled(Image)`
    height: 100%;
    border: 1px solid lightgray;
    margin-right: 10px;
    cursor: pointer;
    position: relative;
    left: -1px;
    bottom: -1px;
`;
