import { IEvent } from "@gift/types";
import { Layout, Title } from "@gift/ui";
import { EventActions, log, useCurrentPage } from "@gift/utils";
import React, { useEffect, useState } from "react";
import {
    Form_EventEdit,
    IFormData,
} from "../../../components/organisms/forms/EventEdit";

export const Page_EventEdit: React.FC = () => {
    const {
        navigateTo,
        params: { id: eventId },
    } = useCurrentPage({ title: "Upravit přání" });

    const [event, setEvent] = useState<IEvent>();

    const handleSubmit = (formData: IFormData) => {
        EventActions.update(eventId, formData);

        navigateTo.eventDetail(event!.id);
    };

    useEffect(() => {
        EventActions.get(eventId)
            .then(setEvent)
            .catch((error) => {
                log({
                    code: "EXCEPTION",
                    scope: Page_EventEdit.name,
                    error,
                });
            });
    }, [eventId]);

    return event ? (
        <Layout width="narrow">
            <Title level={1}>Upravit přání</Title>

            <Form_EventEdit event={event} onSubmit={handleSubmit} />
        </Layout>
    ) : null;
};
