import { ICartItem, IEvent, IPricesListItem, ITemplateItem } from "@gift/types";
import { TCurrencyCodes } from "@honzachalupa/utils";

export const calcEventsAmountSum = (events: IEvent[]) =>
    events.reduce((prevValue, { amount }) => prevValue + amount, 0);

export const calcCartItemsQuantitySum = (items: ICartItem[]) =>
    items
        .map(({ quantity }) => quantity)
        .reduce((prevValue, value) => prevValue + value, 0);

export const calcCartItemsPricesSum = (
    items: ICartItem[],
    prices: IPricesListItem[],
) =>
    items
        .map(
            ({ id, quantity }) =>
                prices.find((price) => price.id === id)!.value * quantity,
        )
        .reduce((prevValue, value) => prevValue + value, 0);

export const calcContributionsAmountSum = (
    contributions: IEvent["contributions"],
) =>
    contributions.reduce(
        (prevValue, contribution) =>
            prevValue +
            (contribution.items || []).reduce(
                (prevValue, { pricePerItem, quantity }) =>
                    prevValue + pricePerItem * quantity,
                0,
            ),
        0,
    );

export const calcFixedPricesSum = (
    items: ITemplateItem[],
    currencyCode: TCurrencyCodes,
) =>
    items
        .filter(({ price }) => price.type === "FIXED")
        .reduce(
            (prevValue, { price, quantity }) =>
                prevValue + price.values![currencyCode]! * quantity,
            0,
        );

export const calcTemplateItemsCoef = (items: ITemplateItem[]) =>
    items
        .filter(({ price }) => price.type === "PORTION")
        .reduce(
            (prevValue, { price, quantity }) =>
                prevValue + price.value! * quantity,
            0,
        );

export const calcTemplateItemPrice = (
    item: ITemplateItem,
    amount: number,
    currencyCode: TCurrencyCodes,
    fixedPricesSum: number,
    templateItemsCoef: number,
) =>
    item.price.type === "FIXED"
        ? item.price.values![currencyCode]!
        : Math.round(
              (Math.max(amount - fixedPricesSum, 1) / templateItemsCoef) *
                  item.price.value!,
          );

export const calcTemplateItemsAmount = (
    items: ITemplateItem[],
    amount: number,
    currencyCode: TCurrencyCodes,
) => {
    const fixedPricesSum = calcFixedPricesSum(items, currencyCode);
    const templateItemsCoef = calcTemplateItemsCoef(items);

    const prices: IPricesListItem[] = items.map((item) => ({
        id: item.id,
        value: calcTemplateItemPrice(
            item,
            amount,
            currencyCode,
            fixedPricesSum,
            templateItemsCoef,
        ),
    }));

    return {
        prices,
        minAmount: fixedPricesSum,
    };
};
