import { IContext } from "@gift/types";
import { Button, ButtonsGroup, Layout } from "@gift/ui";
import { Context, useCurrentPage } from "@gift/utils";
import { scrollToRef } from "@honzachalupa/utils";
import React, { useContext, useEffect } from "react";
import { ContentBlock_1 } from "../../components/organisms/content-blocks/1";
import { ContentBlock_2 } from "../../components/organisms/content-blocks/2";
import { ContentBlock_4 } from "../../components/organisms/content-blocks/4";
import { ContentBlock_9 } from "../../components/organisms/content-blocks/9";
import { ContentBlock_About } from "../../components/organisms/content-blocks/About";
import { ContentBlock_Benefits } from "../../components/organisms/content-blocks/Benefits";
import { ContentBlock_EventsPreview } from "../../components/organisms/content-blocks/EventsPreview";
import { ContentBlock_FAQ } from "../../components/organisms/content-blocks/FAQ";
import { ContentBlock_HowItWorks } from "../../components/organisms/content-blocks/HowItWorks";
import { ContentBlock_References } from "../../components/organisms/content-blocks/References";
import { Header } from "./components/Header";

export const Page_Home: React.FC = () => {
    const { navigateTo, location, params } = useCurrentPage({
        title: "",
    });

    const { navigationRefs } = useContext<IContext>(Context);

    useEffect(() => {
        if (navigationRefs?.[location.state?.scrollToRefName]) {
            setTimeout(() => {
                scrollToRef(navigationRefs[location.state.scrollToRefName]);
            }, 1);
        }
    }, [navigationRefs, params, location.state?.scrollToRefName]);

    return (
        <Layout>
            <Header />

            <ContentBlock_1 />
            <ContentBlock_2 />
            <ContentBlock_EventsPreview />

            <ButtonsGroup>
                <Button
                    label="Vyzkoušet zdarma"
                    color="accentSecondary"
                    size="big"
                    onClick={navigateTo.eventCreate}
                />
            </ButtonsGroup>

            <ContentBlock_Benefits />
            <ContentBlock_4 />
            <ContentBlock_HowItWorks />
            <ContentBlock_References />
            <ContentBlock_FAQ />
            <ContentBlock_About />
            <ContentBlock_9 />

            <ButtonsGroup>
                <Button
                    label="Začněte měnit přání ve skutečnost"
                    color="accentSecondary"
                    size="big"
                    onClick={navigateTo.eventCreate}
                />
            </ButtonsGroup>
        </Layout>
    );
};
