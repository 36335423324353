import { IContext } from "@gift/types";
import { Context } from "@gift/utils";
import { useContext } from "react";
import themes from "./themes";

export const useThemeSelector = (override?: string) => {
    const { theme } = useContext<IContext>(Context);

    if ((override || theme) === "wedding") {
        return themes.themeWedding;
    }

    return themes.themeGeneral;
};
