import { config, useNavigation, useTranslation } from "@gift/utils";
import moment from "moment";
import React from "react";
import { ButtonsGroup, LayoutMargin } from "../../../../";
import {
    StyledButton,
    StyledContainer,
    StyledCopyright,
} from "./Footer.styled";

export const Footer: React.FC = () => {
    const navigateTo = useNavigation();
    const t = useTranslation();

    return (
        <StyledContainer>
            <LayoutMargin>
                <StyledCopyright alignment="center">
                    &copy; Copyright 2021 - {moment().year()}{" "}
                    {config.developerName} Všechna práva vyhrazena.
                </StyledCopyright>

                <ButtonsGroup>
                    <StyledButton
                        label={t("buttonLabel_contact")}
                        color="transparentInverted"
                        onClick={navigateTo.contactUs}
                    />

                    <StyledButton
                        label={t("buttonLabel_processingOfPersonalData")}
                        color="transparentInverted"
                        onClick={navigateTo.personalDataProtection}
                    />

                    <StyledButton
                        label={t("buttonLabel_termsAndConditions")}
                        color="transparentInverted"
                        onClick={navigateTo.termsAndConditions}
                    />
                </ButtonsGroup>
            </LayoutMargin>
        </StyledContainer>
    );
};
