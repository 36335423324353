import { Layout, LayoutMargin, Text, Title } from "@gift/ui";
import { config, useCurrentPage, useTranslation } from "@gift/utils";

export const Page_PersonalDataProtection: React.FC = () => {
    const t = useTranslation();
    useCurrentPage({
        title: t("buttonLabel_processingOfPersonalData"),
    });

    return (
        <Layout>
            <Title level={1}>{t("buttonLabel_processingOfPersonalData")}</Title>

            <LayoutMargin>
                <Title level={3}>I. Základní ustanovení</Title>

                <Text>
                    <ol>
                        <li>
                            Správcem osobních údajů podle čl. 4 bod 7 nařízení
                            Evropského parlamentu a Rady (EU) 2016/679 o ochraně
                            fyzických osob v souvislosti se zpracováním osobních
                            údajů a o volném pohybu těchto údajů (dále jen:
                            „GDPR”) je: BrightWorks s.r.o., IČ: 14060078,
                            sídlem: Žižkova 708, Příbram - 261 01, zapsána v
                            obchodním rejstříku vedeném Městským soudem v Praze,
                            oddíl C, vložka 359764 (dále jen: „správce“).
                        </li>
                        <li>
                            Kontaktní údaje správce jsou adresa: Žižkova 708,
                            Příbram - 261 01, e-mail:{" "}
                            {config.supportEmailAddress}
                        </li>
                        <li>
                            Osobními údaji se rozumí veškeré informace o
                            identifikované nebo identifikovatelné fyzické osobě;
                            identifikovatelnou fyzickou osobou je fyzická osoba,
                            kterou lze přímo či nepřímo identifikovat, zejména
                            odkazem na určitý identifikátor, například jméno,
                            identifikační číslo, lokační údaje, síťový
                            identifikátor nebo na jeden či více zvláštních prvků
                            fyzické, fyziologické, genetické, psychické,
                            ekonomické, kulturní nebo společenské identity této
                            fyzické osoby.
                        </li>
                        <li>
                            Správce nejmenoval pověřence pro ochranu osobních
                            údajů.
                        </li>
                    </ol>
                </Text>

                <Title level={3}>
                    II. Zdroje a kategorie zpracovávaných osobních údajů
                </Title>

                <Text>
                    <ol>
                        <li>
                            Správce zpracovává osobní údaje, které jste mu
                            poskytl/a nebo osobní údaje, které správce získal na
                            základě plnění Vaší objednávky, zejména se jedná o
                            Vaše
                            <ul>
                                <li>jméno, příjmení, titul</li>
                                <li>e-mailová adresa</li>
                                <li>telefonní číslo</li>
                                <li>IP adresa</li>
                                <li>č. bankovníhonebo platebního účtu</li>
                            </ul>
                        </li>
                        <li>
                            Správce zpracovává Vaše identifikační a kontaktní
                            údaje a údaje nezbytné pro plnění smlouvy.
                        </li>
                    </ol>
                </Text>

                <Title level={3}>
                    III. Zákonný důvod a účel zpracování osobních údajů
                </Title>

                <Text>
                    <ol>
                        <li>
                            Zákonným důvodem zpracování osobních údajů je
                            <ul>
                                <li>
                                    plnění smlouvy mezi Vámi a správcem podle
                                    čl. 6 odst. 1 písm. b) GDPR,
                                </li>
                                <li>
                                    oprávněný zájem správce na poskytování
                                    přímého marketingu (zejména pro zasílání
                                    obchodních sdělení a newsletterů) podle čl.
                                    6 odst. 1 písm. f) GDPR,
                                </li>
                                <li>
                                    Váš souhlas se zpracováním pro účely
                                    poskytování přímého marketingu (zejména pro
                                    zasílání obchodních sdělení a newsletterů)
                                    podle čl. 6 odst. 1 písm. a) GDPR.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Účelem zpracování osobních údajů je
                            <ul>
                                <li>
                                    vyřízení Vaší objednávky a výkon práv a
                                    povinností vyplývajících ze smluvního vztahu
                                    mezi Vámi a správcem; při objednávce jsou
                                    vyžadovány osobní údaje, které jsou nutné
                                    pro úspěšné vyřízení objednávky (jméno a
                                    adresa, kontakt), poskytnutí osobních údajů
                                    je nutným požadavkem pro uzavření a plnění
                                    smlouvy, bez poskytnutí osobních údajů není
                                    možné smlouvu uzavřít či jí ze strany
                                    správce plnit,
                                </li>
                                <li>
                                    zasílání obchodních sdělení a činění dalších
                                    marketingových aktivit, pokud jste s tímto
                                    účelem vyslovil souhlas.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Ze strany správce nedochází k automatickému
                            individuálnímu rozhodování ve smyslu čl. 22 GDPR.
                            IV. Doba uchovávání údajů
                        </li>
                    </ol>
                </Text>

                <Text>
                    <ol>
                        <li>
                            Správce uchovává osobní údaje
                            <ul>
                                <li>
                                    po dobu nezbytnou k výkonu práv a povinností
                                    vyplývajících ze smluvního vztahu mezi Vámi
                                    a správcem a uplatňování nároků z těchto
                                    smluvních vztahů (po dobu 15 let od ukončení
                                    smluvního vztahu).
                                </li>
                                <li>
                                    po dobu, než je odvolán souhlas se
                                    zpracováním osobních údajů pro účely
                                    marketingu, nejdéle 10 let, jsou-li osobní
                                    údaje zpracovávány na základě souhlasu.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Po uplynutí doby uchovávání osobních údajů správce
                            osobní údaje vymaže.
                        </li>
                    </ol>
                </Text>

                <Title level={3}>
                    V. Příjemci osobních údajů (subdodavatelé správce)
                </Title>

                <Text>
                    <ol>
                        <li>
                            Příjemci osobních údajů jsou osoby:
                            <ul>
                                <li>
                                    podílející se na dodání zboží / služeb /
                                    realizaci plateb na základě smlouvy,
                                </li>
                                <li>
                                    zajišťující služby provozování e-shopu
                                    www.dareknasvatbu.cz a další služby v
                                    souvislosti s provozováním e-shopu,
                                </li>
                                <li>
                                    zajišťující správu serveru a e-mailových
                                    klientů správce,
                                </li>
                                <li>
                                    zajišťující správu zákaznických programů,
                                </li>
                                <li>zajišťující marketingové služby,</li>
                                <li>advokát,</li>
                                <li>účetní a daňoví poradci,</li>
                                <li>
                                    osoby, kterým poskytujeme údaje za účelem
                                    analýzy návštěvnosti našich webů,
                                </li>
                                <li>
                                    osoby, které pro nás zajišťují dostatečné
                                    zabezpečení a integritu našich služeb a webů
                                    a toto zabezpečení také pravidelně testují.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Správce nemá v úmyslu předat osobní údaje do třetí
                            země (do země mimo EU) nebo mezinárodní organizaci,
                            vyjma předávání údajů společnosti Google za účelem
                            sběru tzv. cookies dle čl. VIII těchto zásad.
                        </li>
                        <li>
                            Správce je též ze zákona povinen osobní údaje předat
                            správním, daňovým, policejním a soudním orgánům.
                        </li>
                    </ol>
                </Text>

                <Title level={3}>VI. Vaše práva</Title>

                <Text>
                    <ol>
                        <li>
                            Za podmínek stanovených v GDPR máte
                            <ul>
                                <li>
                                    právo na přístup ke svým osobním údajům dle
                                    čl. 15 GDPR,
                                </li>
                                <li>
                                    právo opravu osobních údajů dle čl. 16 GDPR,
                                    popřípadě omezení zpracování dle čl. 18
                                    GDPR,
                                </li>
                                <li>
                                    právo na výmaz osobních údajů dle čl. 17
                                    GDPR,
                                </li>
                                <li>
                                    právo vznést námitku proti zpracování dle
                                    čl. 21 GDPR,
                                </li>
                                <li>
                                    právo na přenositelnost údajů dle čl. 20
                                    GDPR,
                                </li>
                                <li>
                                    právo odvolat souhlas se zpracováním písemně
                                    nebo elektronicky na adresu nebo email
                                    správce uvedený v čl. III těchto podmínek.
                                    Odvolání souhlasu nemá vliv na zpracování
                                    osobních údajů zpracovávaných na jiném
                                    právním základu, než je souhlas,
                                </li>
                                <li>
                                    právo na omezení zpracování osobních údajů,
                                </li>
                                <li>
                                    Právo na oznámení ohledně opravy, výmazu
                                    nebo omezení zpracování osobních údajů,
                                </li>
                                <li>
                                    Právo nebýt předmětem automatizovaného
                                    individuálního rozhodování, včetně
                                    profilování,
                                </li>
                                <li>
                                    Právo na oznámení porušení zabezpečení
                                    Vašich osobních údajů ze strany Správce a
                                    zároveň povinnost Správce Vás o porušení
                                    informovat. 2. Dále máte právo podat
                                    stížnost u Úřadu pro ochranu osobních údajů
                                    v případě, že se domníváte, že bylo porušeno
                                    Vaší právo na ochranu osobních údajů.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Dále máte právo podat stížnost u Úřadu pro ochranu
                            osobních údajů v případě, že se domníváte, že bylo
                            porušeno Vaší právo na ochranu osobních údajů.
                        </li>
                    </ol>
                </Text>

                <Title level={3}>
                    VII. Podmínky zabezpečení osobních údajů
                </Title>

                <Text>
                    <ol>
                        <li>
                            Správce prohlašuje, že přijal veškerá vhodná
                            technická a organizační opatření k zabezpečení
                            osobních údajů.
                        </li>
                        <li>
                            Správce přijal technická opatření k zabezpečení
                            datových úložišť a úložišť osobních údajů v listinné
                            podobě.
                        </li>
                        <li>
                            Správce prohlašuje, že k osobním údajům mají přístup
                            pouze jím pověřené osoby.
                        </li>
                    </ol>
                </Text>

                <Title level={3}>VIII. Cookies</Title>

                <Text>
                    <ol>
                        <li>
                            Účelem zpracování osobních údajů návštěvníků
                            prostřednictvím „cookies“ je sběr dat pro službu
                            Google Analytics. Informace o užívání stránky spolu
                            s obsahem souboru cookie bude společností Google
                            přenesen a uložen na serverech ve Spojených státech
                            amerických. Google bude užívat těchto informací pro
                            účely vyhodnocování užívání stránky a vytváření
                            zpráv o její aktivitě, určených pro její
                            provozovatele, a pro poskytování dalších služeb
                            týkajících se činnosti na stránce a užívání
                            internetu vůbec. Google může také poskytnout tyto
                            informace třetím osobám, bude-li to požadováno
                            zákonem nebo budou-li takovéto třetí osoby
                            zpracovávat tyto informace pro Google.
                        </li>
                        <li>
                            Více informací o zpracování a využití dat najdete ve
                            smluvních podmínkách společnosti Google. Chcete-li
                            zablokovat odesílání anonymních statistik pomocí
                            nástroje Google Analytics, můžete k tomu využít
                            plugin poskytovaný společností Google:
                            https://tools.google.com/dlpage/gaoptout. Tento
                            plugin je třeba stáhnout a nainstalovat do vašeho
                            prohlížeče. Osobní údaje shromážděné Google
                            Analytics jsou ukládány výhradně společností Google.
                            Správce je neukládá na žádném lokálním ani vzdáleném
                            úložišti.
                        </li>
                    </ol>
                </Text>

                <Title level={3}>VIII. Závěrečná ustanovení</Title>

                <Text>
                    <ol>
                        <li>
                            Odesláním objednávky z internetového objednávkového
                            formuláře potvrzujete, že jste seznámen/a s
                            podmínkami ochrany osobních údajů a že je v celém
                            rozsahu přijímáte.
                        </li>
                        <li>
                            Potvrzujete seznámení se s těmito podmínkami,
                            zaškrtnutím souhlasu prostřednictvím internetového
                            formuláře. Zaškrtnutím souhlasu potvrzujete, že jste
                            seznámen/a s podmínkami ochrany osobních údajů a že
                            je v celém rozsahu přijímáte.
                        </li>
                        <li>
                            Potvrzujete seznámení se s těmito podmínkami
                            přijetím smlouvy, dohody nebo přihlášky, ve které je
                            na tyto podmínky výslovně odkazováno.
                        </li>
                        <li>
                            Správce je oprávněn tyto podmínky změnit. Novou
                            verzi podmínek ochrany osobních údajů zveřejní na
                            svých internetových stránkách a zároveň Vám zašle
                            novou verzi těchto podmínek Vaši e-mailovou adresu,
                            kterou jste správci poskytl/a.
                        </li>
                    </ol>
                </Text>

                <Text>Tyto podmínky nabývají účinnosti dnem 1.1.2022.</Text>
            </LayoutMargin>
        </Layout>
    );
};
