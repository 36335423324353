import { IContext } from "@gift/types";
import { createContext } from "react";

export const initialContext: IContext = {
    user: undefined,
    language: undefined as any,
    translations: undefined as any,
    currentRoute: undefined,
    navigationRefs: {},
    theme: "wedding",
    isScrollDisabled: false,
    isCookiesAllowed: false,
    setCurrentRoute: () => {},
    setNavigationRef: () => {},
    setIsCookiesAllowed: () => {},
    setIsScrollDisabled: () => {},
};

export const Context = createContext(initialContext);
