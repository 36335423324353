import { ITemplateItem } from "@gift/types";
import { IModalRefProps, Modal } from "@gift/ui";
import { TCurrencyCodes } from "@honzachalupa/utils";
import { forwardRef, RefAttributes } from "react";
import { Form_CreateTemplateItem } from "../forms/CreateTemplateItem";

export interface IProps {
    currencyCode: TCurrencyCodes;
    onSubmit: (item: ITemplateItem) => void;
}

export const Modal_CreateTemplateItem: React.FC<
    IProps & RefAttributes<IModalRefProps>
> = forwardRef(({ currencyCode, onSubmit }, ref) => (
    <Modal ref={ref}>
        <h1>Přidat vlastní položku</h1>

        <Form_CreateTemplateItem
            currencyCode={currencyCode}
            onSubmit={(item) => {
                onSubmit(item);

                // @ts-ignore
                ref?.current.hide();
            }}
        />
    </Modal>
));
