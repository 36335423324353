import { EPatterns, IFormInput } from "@gift/types";
import { Form, Loader } from "@gift/ui";
import {
    Authentication,
    isDev,
    log,
    useCurrentPage,
    UsersActions,
    useTranslation,
} from "@gift/utils";
import { Fragment, useMemo, useState } from "react";

interface IFormData {
    emailAddress: string;
    password: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
}

export interface IProps {
    handleCreateEvent?: () => () => void;
}

export const Form_SignUp: React.FC<IProps> = ({ handleCreateEvent }) => {
    const t = useTranslation();
    const { navigateTo } = useCurrentPage({
        title: t("headline_signUp"),
    });

    const [isLoading, setIsLoading] = useState<boolean>();

    const handleSubmit = ({
        emailAddress,
        password,
        firstName,
        lastName,
        phoneNumber,
    }: IFormData) => {
        setIsLoading(true);

        Authentication.createUserWithEmailAndPassword(emailAddress, password)
            .then(({ user }) => {
                UsersActions.create(user?.uid, {
                    emailAddress,
                    firstName,
                    lastName,
                    phoneNumber: phoneNumber.replace(/\s/g, ""),
                })
                    .then(() => handleCreateEvent?.() || navigateTo.home())
                    .catch((error) => {
                        log({
                            code: "EXCEPTION",
                            scope: Form_SignUp.name,
                            error,
                        });
                    });
            })
            .catch((error) => {
                log({
                    code: "EXCEPTION",
                    scope: Form_SignUp.name,
                    error,
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <Fragment>
            <Form
                inputs={useMemo<IFormInput[]>(
                    () => [
                        {
                            id: "emailAddress",
                            label: t("inputLabel_emailAddress"),
                            controlType: "input",
                            inputType: "email",
                            rules: {
                                required: true,
                                pattern: isDev
                                    ? /@brightworks\.cz$/
                                    : EPatterns.EMAIL_ADDRESS,
                            },
                        },
                        {
                            id: "password",
                            label: t("inputLabel_password"),
                            controlType: "input",
                            inputType: "password",
                            rules: {
                                required: true,
                            },
                        },
                        {
                            id: "firstName",
                            label: t("inputLabel_firstName"),
                            controlType: "input",
                            rules: {
                                required: true,
                            },
                        },
                        {
                            id: "lastName",
                            label: t("inputLabel_lastName"),
                            controlType: "input",
                            rules: {
                                required: true,
                            },
                        },
                        {
                            id: "phoneNumber",
                            label: t("inputLabel_phoneNumber"),
                            controlType: "input",
                            inputType: "tel",
                            rules: {
                                required: true,
                                pattern: EPatterns.PHONE_NUMBER,
                            },
                        },
                    ],
                    [t],
                )}
                buttonsRenderer={(submit) => [
                    {
                        label: t("buttonLabel_signUp"),
                        onClick: submit,
                    },
                ]}
                onSubmit={handleSubmit}
            />

            {isLoading && <Loader isFullscreen />}
        </Fragment>
    );
};
