import { Layout, Title } from "@gift/ui";
import { useCurrentPage } from "@gift/utils";
import { Form_ContactUs } from "../../components/organisms/forms/ContactUs";

export const Page_ContactUs: React.FC = () => {
    useCurrentPage({
        title: "Kontaktujte nás",
    });

    return (
        <Layout>
            <Title level={1}>Kontaktujte nás</Title>

            <Form_ContactUs />
        </Layout>
    );
};
