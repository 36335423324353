import { IEvent } from "@gift/types";
import {
    Button,
    ButtonsGroup,
    EventPreview,
    Layout,
    Text,
    Title,
} from "@gift/ui";
import { EventActions, log, useCurrentPage, useTranslation } from "@gift/utils";
import { Fragment, useEffect, useState } from "react";

export const Page_Account: React.FC = () => {
    const t = useTranslation();
    const { user, navigateTo } = useCurrentPage({
        title: t("headline_myEvents"),
    });

    const [events, setEvents] = useState<IEvent[]>();

    useEffect(() => {
        if (user) {
            EventActions.search({
                where: [["ownerId", "==", user.id]],
            })
                .then(setEvents)
                .catch((error) => {
                    log({
                        code: "EXCEPTION",
                        scope: Page_Account.name,
                        error,
                    });
                });
        }
    }, [user]);

    return (
        <Layout width="narrow">
            <Title level={1}>{t("headline_myEvents")}</Title>

            {events && events.length > 0 ? (
                events?.map((event) => (
                    <EventPreview key={event.id} {...event} />
                ))
            ) : (
                <Fragment>
                    <Text alignment="center">
                        {t("content_noExistingProjects")}
                    </Text>

                    <ButtonsGroup>
                        <Button
                            label={t("buttonLabel_createFirstProject")}
                            color="accentSecondary"
                            onClick={navigateTo.eventCreate}
                        />
                    </ButtonsGroup>
                </Fragment>
            )}
        </Layout>
    );
};
