import { Layout, Title } from "@gift/ui";
import { useCurrentPage } from "@gift/utils";

export const Page_NotFound: React.FC = () => {
    useCurrentPage({
        title: "Stránka nalezena",
    });

    return (
        <Layout>
            <Title level={1}>Stránka nebyla nalezena</Title>
        </Layout>
    );
};
