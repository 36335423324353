import styled from "styled-components";
import { Image, Text, Title } from "../../../";
import { ProgressBox } from "../ProgressBox";

export const StyledEventContainer = styled.div`
    flex-basis: calc(50% - 10px);
    box-shadow: 0 0 15px ${({ theme }) => theme.colors.grayLight};
    padding: 20px;
    margin: 10px 0;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        flex-basis: 100%;

        &:nth-child(3),
        &:nth-child(4) {
            display: none;
        }
    }
`;

export const StyledTitle = styled(Title)`
    margin-top: 0;
`;

export const StyledHeader = styled.header`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
`;

export const StyledContentContainer = styled.div`
    width: 48%;

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        width: 100%;
    }
`;

export const StyledImage = styled(Image)`
    width: 100%;
    aspect-ratio: 16/10;
    object-fit: cover;
    box-shadow: 0 0 15px ${({ theme }) => theme.colors.grayLight};
    margin-bottom: 20px;
`;

export const StyledProgressBox = styled(ProgressBox)`
    zoom: 0.9;
`;

export const StyledText = styled(Text)`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 20px;
`;
