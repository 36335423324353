import { ButtonsGroup } from "@gift/ui";
import styled from "styled-components";

export const StyledLink = styled.a`
    margin-bottom: 50px;
`;

export const StyledButtonsGroup = styled(ButtonsGroup)`
    margin-top: 50px;
`;
