import { ContentBlockGrid } from "@gift/ui";

export const ContentBlock_References: React.FC = () => (
    <ContentBlockGrid
        subheadline="Co se o službě povídá?"
        headline="„Téměř měsíc před svatbou jsme měli vybráno víc, než jsme si přáli.“"
        description="DárekNaSvatbu.cz je tady pro snoubence, kteří mají zařízenou domácnost, ale také jeden velký sen. Třeba o svatební cestě na Bali. Nejvíc ocení finanční příspěvek, který jim pomůže proměnit sny ve skutečnost."
        backgroundColors={["#EFF5F7", "#E4E0EB", "#f6f3ea"]}
    />
);
