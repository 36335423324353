import { Layout } from "@gift/ui";
import { useTranslation } from "@gift/utils";
import { Form_SignUp } from "../../../components/organisms/forms/SignUp";

export const Page_AuthenticationSignUp: React.FC = () => {
    const t = useTranslation();

    return (
        <Layout>
            <h1>{t("headline_signUp")}</h1>

            <Form_SignUp />
        </Layout>
    );
};
