export enum EInvoiceStates {
    CREATED = "CREATED",
    ISSUED = "ISSUED",
    PAID = "PAID",
}

export enum EInvoiceStateLabels {
    CREATED = "Čeká na zpracování",
    ISSUED = "Odesláno",
    PAID = "Zaplaceno",
}
