import { EPaymentStates, IPayment } from "@gift/types";
import { convertDocToItem } from "@honzachalupa/firebase";
import { IAbstractObject } from "@honzachalupa/utils";
import { Collections, Database } from "../helpers/firebase";

export const PaymentActions = {
    create: (paymentId: number, options: IAbstractObject) =>
        Database.set(Collections.payments, paymentId, {
            ...options,
            state: EPaymentStates.PENDING,
        }),

    get: (paymentId: number): Promise<IPayment> =>
        Database.get(Collections.payments, paymentId)
            .then(convertDocToItem)
            .catch((error) => error),

    updateState: (
        paymentId: number,
        state: EPaymentStates.SUCCESS | EPaymentStates.FAILED,
    ) =>
        Database.update(Collections.payments, paymentId, {
            state,
        }),

    setIsCertificatesSent: (paymentId: number) =>
        Database.update(Collections.payments, paymentId, {
            isCertificatesSent: true,
        }),
};
