import { ERoutePaths } from "@gift/types";
import { Route } from "react-router-dom";
import { Page_AuthenticationSignIn } from "./SignIn";
import { Page_AuthenticationSignUp } from "./SignUp";

export default [
    <Route
        path={ERoutePaths.AUTHENTICATION_SIGN_IN}
        element={<Page_AuthenticationSignIn />}
    />,

    <Route
        path={ERoutePaths.AUTHENTICATION_SIGN_UP}
        element={<Page_AuthenticationSignUp />}
    />,
];
