import { ERoutePaths } from "@gift/types";
import { config } from "..";
import { log } from "./logging";

// TODO: Add test.
export const convertBBANtoIBAN = async (bban: string) => {
    const bbanSplitted = bban.split("/");

    const { iban }: { iban: string } = await fetch(
        `https://openiban.com/v2/calculate/CZ/${bbanSplitted[1]}/${bbanSplitted[0]}`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        },
    )
        .then((response) => response.json())
        .catch((error) => {
            log({
                code: "EXCEPTION",
                scope: convertBBANtoIBAN.name,
                error,
            });
        });

    return iban;
};

// TODO: Add test.
export const getPaymentUrl = async (
    {
        amount,
        iban,
        varsymbol,
    }: {
        amount: number;
        iban: string;
        varsymbol: number;
    },
    confirmationEmailAddress: string,
) => {
    const callbackUrl = `${window.location.origin}${ERoutePaths.PAYMENT_CONFIRMATION}`;

    const options = {
        shopid: config.csasPaymentShopId,
        amount,
        varsymbol,
        url: callbackUrl,
        email: confirmationEmailAddress,
        iban,
        sign: config.csasPaymentSignKey,
    };

    const params = Object.entries(options)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");

    const msgUint8 = new TextEncoder().encode(params);
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hash = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");

    return encodeURI(
        `https://www.platbazuctu.cz/?${params.replace(options.sign, hash)}`,
    );
};
