import {
    ILogProps,
    initializeLogger,
    TLogEntryLevels,
} from "@honzachalupa/utils";
import { config } from "../";
import packageJson from "../../../../../package.json";
import { isDev } from "./app";

type TLogEntryCodes =
    | "FUNCTION_TRIGGERED"
    | "FUNCTION_STARTED"
    | "FUNCTION_FINISHED"
    | "EXCEPTION"
    | "TRANSLATION_NOT_FOUND"
    | "LOCALE_NOT_FOUND"
    | "SECURITY_BREACH"
    | "PAYMENT_FAILED"
    | "DEBUGGING";

const levelsMap: {
    [key in TLogEntryLevels]: TLogEntryCodes[];
} = {
    info: [
        "FUNCTION_TRIGGERED",
        "FUNCTION_STARTED",
        "FUNCTION_FINISHED",
        "LOCALE_NOT_FOUND",
        "DEBUGGING",
    ],
    warn: ["PAYMENT_FAILED"],
    error: [],
};

const logger = initializeLogger({
    logtailToken: isDev ? config.logtailTokenDev : config.logtailTokenProd,
    levelsMap,
    appVersion: packageJson.version,
});

export const log = (props: ILogProps & { code: TLogEntryCodes }) => {
    logger.log(props);
};
