import styled from "styled-components";
import { Text } from "../../../";

export const StyledSwitchContainer = styled.div`
    text-align: right;
`;

export const StyledContainer = styled.div`
    box-shadow: 0 0 15px ${({ theme }) => theme.colors.grayLight};
    padding: 15px;
    margin-bottom: 20px;
`;

export const StyledCategoryLabel = styled(Text)`
    color: ${({ theme }) => theme.colors.accentSecondary};
    font-weight: 600;

    &:not(:first-of-type) {
        margin-top: 30px;
    }
`;

export const StyledCustomItemBlock = styled.div`
    padding: 30px 15%;
`;
