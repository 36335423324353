import { IEvent, IPayment } from "@gift/types";
import { useMemo } from "react";
import { PdfDocumentPreview } from "../../../";
import { TemplateItemCertificateDocument } from "../TemplateItemCertificateDocument";
import {
    StyledButtonsGroup,
    StyledDownloadButton,
    StyledItemContainer,
    StyledPreviewContainer,
} from "./PdfDocumentsList.styled";

export interface IProps {
    event: IEvent;
    payment: IPayment;
}

export const PdfDocumentsList: React.FC<IProps> = ({ event, payment }) => {
    const certificateDocuments = useMemo(
        () =>
            payment.items.map((item) => (
                <TemplateItemCertificateDocument
                    event={event}
                    item={item}
                    formData={payment.certificateData}
                />
            )),
        [],
    );

    return (
        <div>
            {certificateDocuments.map((document, i) => (
                <StyledItemContainer key={i}>
                    <StyledPreviewContainer>
                        <PdfDocumentPreview document={document} />
                    </StyledPreviewContainer>

                    <StyledButtonsGroup>
                        <StyledDownloadButton
                            fileName={`certifikat-${i + 1}.pdf`}
                            document={document}
                        >
                            Stáhnout certifikát
                        </StyledDownloadButton>
                    </StyledButtonsGroup>
                </StyledItemContainer>
            ))}
        </div>
    );
};
