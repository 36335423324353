import { Layout } from "@gift/ui";
import { useCurrentPage, useTranslation } from "@gift/utils";
import React from "react";
import { Form_SignIn } from "../../../components/organisms/forms/SignIn";

export const Page_AuthenticationSignIn: React.FC = () => {
    const t = useTranslation();
    useCurrentPage({ title: t("headline_signIn") });

    return (
        <Layout>
            <h1>{t("headline_signIn")}</h1>

            <Form_SignIn />
        </Layout>
    );
};
