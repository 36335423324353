import { StyleSheet } from "@react-pdf/renderer";

export default StyleSheet.create({
    page: {
        fontFamily: "Barlow",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
    },
    section: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
    },
    eventName: {
        fontSize: 35,
        color: "#E42552",
    },
    image: {
        width: 320,
        height: 200,
        marginTop: 20,
    },
    mainText: {
        maxWidth: 500,
        marginTop: 20,
    },
    messageText: {
        maxWidth: 500,
        marginTop: 20,
        opacity: 0.8,
    },
});
