import { ESupportedLanguages } from "@gift/types";
import { IAbstractObject, LANGUAGE_TEST } from "@honzachalupa/utils";
import { Collections, Database } from "../helpers/firebase";
import { log } from "../helpers/logging";

export const TranslationsActions = {
    search: (locale: string) => {
        const supportedLocales = [
            ...ESupportedLanguages,
            { id: LANGUAGE_TEST },
        ].map(({ id }) => id);

        const localeFixed = supportedLocales.includes(locale) ? locale : "cs";

        if (!supportedLocales.includes(locale)) {
            log({
                code: "LOCALE_NOT_FOUND",
                scope: "TranslationsActions",
                error: new Error(
                    "Translations for given locale does not exists. Using fallback locale.",
                ),
                data: {
                    locale,
                    fallbackLocale: localeFixed,
                    supportedLocales: ESupportedLanguages.map(({ id }) => id),
                },
            });
        }

        return Database.get(Collections.translations, localeFixed)
            .then((doc) => doc.data())
            .catch((error) => {
                log({
                    code: "EXCEPTION",
                    scope: "TranslationsActions",
                    error,
                });
            }) as Promise<IAbstractObject>;
    },
};
