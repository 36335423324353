import { Layout as LayoutCore } from "@honzachalupa/ui";
import { CookieBanner, LayoutMargin } from "../../";
import { Footer } from "./components/Footer";
import { Navigation } from "./components/Navigation";
import { StyledContent, TLayoutWidth } from "./Main.styled";

export interface IProps {
    width?: TLayoutWidth;
    children: React.ReactNode;
}

export const Layout: React.FC<IProps> = ({ width, children }) => (
    <LayoutCore>
        <LayoutMargin>
            <Navigation />
        </LayoutMargin>

        <StyledContent>
            <LayoutMargin width={width}>{children}</LayoutMargin>
        </StyledContent>

        <Footer />
        <CookieBanner />
    </LayoutCore>
);
