import { IContext } from "@gift/types";
import { Context, useAuthentication, useNavigation } from "@gift/utils";
import React, { Fragment, useContext } from "react";
import { INavigationItems } from "../../Navigation";
import { NavigationItem } from "../components/NavigationItem";
import {
    StyledButton,
    StyledContainer,
    StyledNavigationItem,
    StyledNavigationLeft,
    StyledNavigationRight,
} from "./Desktop.styled";

interface IProps {
    items: INavigationItems;
}

export const View_Desktop: React.FC<IProps> = ({ items }) => {
    const navigateTo = useNavigation();
    const { isUserAuthenticated, isAdmin } = useAuthentication();
    const { currentRoute } = useContext<IContext>(Context);

    return (
        <StyledContainer>
            <StyledNavigationLeft>
                {items.bottom.map(({ label, path, onClick }) => (
                    <NavigationItem
                        key={label}
                        label={label}
                        path={path}
                        onClick={onClick}
                        isSelected={currentRoute?.path === path}
                    />
                ))}
            </StyledNavigationLeft>

            <StyledNavigationRight>
                {isUserAuthenticated ? (
                    <Fragment>
                        {isAdmin && (
                            <StyledNavigationItem
                                label={items.top.administration.label}
                                path={items.top.administration.path}
                            />
                        )}

                        <StyledNavigationItem
                            label={items.top.account.label}
                            path={items.top.account.path}
                        />

                        <StyledNavigationItem
                            label={items.top.signOut.label}
                            onClick={items.top.signOut.onClick}
                        />
                    </Fragment>
                ) : (
                    <Fragment>
                        <StyledNavigationItem
                            label={items.top.signIn.label}
                            path={items.top.signIn.path}
                        />

                        <StyledNavigationItem
                            label={items.top.signUp.label}
                            path={items.top.signUp.path}
                        />
                    </Fragment>
                )}

                <StyledButton
                    label={items.top.createEvent.label}
                    color="accentSecondary"
                    size="medium"
                    onClick={() => navigateTo.path(items.top.createEvent.path!)}
                />
            </StyledNavigationRight>
        </StyledContainer>
    );
};
