import { IContext } from "@gift/types";
import { ContentBlockRow } from "@gift/ui";
import { Context } from "@gift/utils";
import { useContext, useEffect, useRef } from "react";

export const ContentBlock_HowItWorks: React.FC = () => {
    const ref = useRef<HTMLElement>(null);

    const { setNavigationRef } = useContext<IContext>(Context);

    useEffect(() => {
        setNavigationRef("howItWorks", ref);
    }, []);

    return (
        <ContentBlockRow
            ref={ref}
            subheadline="Jak to funguje?"
            headline="Hladce, bezpečně a rychle – než řeknete ANO!"
            description="Moderní technologie mění svět i způsob výběru peněz pro novomanžele. Buďte u toho."
            items={[
                {
                    description:
                        "1. Zaregistrujte se, vytvořte seznam položek a odkaz sdílejte se svatebními hosty.",
                    iconName: "list",
                },
                {
                    description:
                        "2. Každý z hostů si sám vybere, které přání vám vyplní. K tomu získá krásný certifikát.",
                    iconName: "certificate",
                },
                {
                    description:
                        "3. Užijte si splněný sen a jako poděkování pošlete fotku z líbánek.",
                    iconName: "photo",
                },
            ]}
        />
    );
};
