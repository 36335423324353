import styled from "styled-components";
import { Button, Icon } from "../../../";

export const StyledContainer = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.grayLight};
`;

export const StyledFileSelector = styled.div`
    aspect-ratio: 16/9;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
`;

export const StyledFileSelectorRef = styled.input`
    display: none;
`;

export const StyledImagePreview = styled.img`
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
`;

export const StyledFileSelectorIcon = styled(Icon)`
    width: 150px;
    padding: 20px 20px 0 20px;
`;

export const StyledFileSelectorDescription = styled.p`
    font-size: 14px;
    color: ${({ theme }) => theme.fontColors.faded};
    text-align: center;
    padding-bottom: 20px;
`;

export const StyledResetButton = styled(Button)`
    position: absolute;
    top: 20px;
    right: 20px;
`;
