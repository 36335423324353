import styled from "styled-components";

export const StyledButton = styled.button<{ isSelected: boolean }>`
    background-color: transparent;
    color: "#130f40";
    border: none;
    font-size: 18px;
    font-weight: 600;
    // opacity: ${({ isSelected }) => (isSelected ? 1 : 0.6)};
    cursor: pointer;
    padding: 10px 15px;
    transition: 300ms color, opacity;

    &:hover {
        color: ${({ theme }) => theme.colors.accentSecondary};
        opacity: 1;
    }

    &:hover,
    &:focus,
    &:active {
        border: none;
        outline: none;
    }
`;
