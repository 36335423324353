import { ContentBlockRow } from "@gift/ui";

export const ContentBlock_1: React.FC = () => (
    <ContentBlockRow
        subheadline="Zažijte radost z chvíle, kdy se novomanželům rozzáří oči"
        headline="Jeden velký dárek je víc, než sto drobností"
        description="Jak si říct o peníze na svatební cestu pod palmami? Výběr peněz pro nevěstu a ženicha nebyl nikdy zábavnější."
        items={[
            {
                label: "Jednoduchost s elegancí",
                description:
                    "Místo neosobní hotovosti v obálce originální a zábavný dárek. Stačí se zaregistrovat a hostům poslat odkaz e-mailem nebo přidat na kartičce ke svatebnímu oznámení.",
                iconName: "diamond",
            },
            {
                label: "Splněné přání bez starostí",
                description:
                    "Hostům ušetříte čas i starosti s výběrem dárku a dostanete přesně to, co si přejete. Bez lámání hlavy, běhání po obchodech a trapných chvilek. Stojí to za to.",
                iconName: "time",
            },
            {
                label: "Získejte více na vaše sny",
                description:
                    "Už žádné vyhozené peníze za duplicitní a nevhodné dárky. Ať každá vynaložená koruna pomůže uskutečnit vaše přání. Protože vy víte nejlépe, jak ji využít.",
                iconName: "giftcard",
            },
        ]}
    />
);
