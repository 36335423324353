import {
    IPricesListItem,
    ITemplate,
    ITemplateItem,
    TTemplateItemCategory,
    TTemplateItemPriceType,
} from "@gift/types";
import { calcTemplateItemsAmount } from "@gift/utils";
import {
    fillStringVariables,
    formatCurrency,
    TCurrencyCodes,
} from "@honzachalupa/utils";
import {
    Fragment,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import {
    Button,
    ButtonsGroup,
    IModalRefProps,
    List,
    StyledErrorMessage,
    Switch,
    Text,
} from "../../../";
import {
    StyledCategoryLabel,
    StyledContainer,
    StyledCustomItemBlock,
    StyledSwitchContainer,
} from "./TemplateItemsList.styled";

type ITemplateItemWithDisabled = ITemplateItem & { isDisabled: boolean };

export interface IProps {
    template: ITemplate;
    amount: number;
    durationDays: number;
    currencyCode: TCurrencyCodes;
    renderNewItemModal: (
        ref: any,
        onSubmit: (item: ITemplateItem) => any,
    ) => ReactNode;
    onChange: ({
        items,
        prices,
        minAmount,
    }: {
        items: ITemplateItem[];
        prices: IPricesListItem[];
        minAmount: number;
    }) => void;
}

export const TemplateItemsList: React.FC<IProps> = ({
    template,
    amount,
    durationDays,
    currencyCode,
    renderNewItemModal,
    onChange,
}) => {
    const [createdItems, setCreatedItems] = useState<ITemplateItem[]>([]);
    const [disabledItemIDs, setDisabledItemIDs] = useState<
        ITemplateItem["id"][]
    >(
        template.items
            .filter(({ isDefaultChecked }) => !isDefaultChecked)
            .map(({ id }) => id),
    );

    const createModalRef = useRef<IModalRefProps>(null);

    const items = useMemo<ITemplateItem[]>(() => {
        const templateItemsOrder: TTemplateItemPriceType[] = [
            "PORTION",
            "FIXED",
        ];

        return [...(template?.items || []), ...createdItems]
            .map((item) => ({
                ...item,
                quantity: Number(
                    fillStringVariables(item.quantity.toString(), {
                        durationDays,
                    }),
                ),
                availableQuantity: Number(
                    fillStringVariables(item.availableQuantity.toString(), {
                        durationDays,
                    }),
                ),
            }))
            .sort(
                (a, b) =>
                    templateItemsOrder.indexOf(a.price.type) -
                    templateItemsOrder.indexOf(b.price.type),
            );
    }, [template, createdItems, durationDays]);

    const itemsWithDisabled = useMemo(
        () =>
            items.map((item) => ({
                ...item,
                isDisabled: disabledItemIDs.includes(item.id),
            })),
        [items, disabledItemIDs],
    );

    const { prices, minAmount } = useMemo(
        () =>
            calcTemplateItemsAmount(
                items.filter(({ id }) => !disabledItemIDs.includes(id)),
                amount,
                currencyCode,
            ),
        [amount, items, disabledItemIDs],
    );

    const getCalculatedPrice = (itemId: number) =>
        prices.find((price) => price.id === itemId)?.value || 0;

    const getPriceLabel = useCallback(
        ({ id, isDisabled }: ITemplateItemWithDisabled) =>
            !isDisabled
                ? formatCurrency(getCalculatedPrice(id), currencyCode)
                : null,
        [prices],
    );

    const toggleItemDisabled = useCallback(
        (id: number, isDisabled: boolean) => {
            setDisabledItemIDs((prevState) =>
                isDisabled
                    ? [...prevState].filter((itemId) => itemId !== id)
                    : [...prevState, id],
            );
        },
        [],
    );

    useEffect(() => {
        onChange({
            items: itemsWithDisabled.filter(({ isDisabled }) => !isDisabled),
            prices,
            minAmount,
        });
    }, [itemsWithDisabled]);

    const categories: {
        id: TTemplateItemCategory;
        label: string;
    }[] = [
        {
            id: "TRANSPORT",
            label: "Doprava",
        },
        {
            id: "ACCOMMODATION",
            label: "Ubytování",
        },
        {
            id: "FOOD",
            label: "Mlsání",
        },
        {
            id: "ENTERTAINMENT",
            label: "Zážitky",
        },
        {
            id: "RELAX",
            label: "Odpočinek",
        },
        {
            id: "SPORT",
            label: "Sport",
        },
        {
            id: "CUSTOM",
            label: "Vlastní",
        },
    ];

    return (
        <Fragment>
            {renderNewItemModal(createModalRef, (item) =>
                setCreatedItems((prevState) => [...prevState, item]),
            )}

            <StyledContainer>
                {categories.map((category, i) => {
                    const categoryItems = itemsWithDisabled.filter(
                        (item) => item.category === category.id,
                    );

                    return categoryItems.length > 0 ? (
                        <Fragment key={category.id}>
                            <StyledCategoryLabel>
                                {category.label}
                            </StyledCategoryLabel>

                            <List
                                data={categoryItems}
                                columns={[
                                    {
                                        id: "label",
                                        label: "Položka",
                                        renderer: ({
                                            label,
                                        }: ITemplateItem) => (
                                            <p
                                                style={{
                                                    fontWeight: 600,
                                                    color: "black",
                                                }}
                                            >
                                                {label}
                                            </p>
                                        ),
                                    },
                                    {
                                        id: "quantity",
                                        label: "Počet",
                                        renderer: ({
                                            quantity,
                                        }: ITemplateItem) => `${quantity}x`,
                                    },
                                    {
                                        id: "price",
                                        label: "Cena za kus",
                                        renderer: (
                                            item: ITemplateItemWithDisabled,
                                        ) => getPriceLabel(item),
                                    },
                                    {
                                        id: "action",
                                        renderer: ({ id, isDisabled }) => (
                                            <StyledSwitchContainer>
                                                <Switch
                                                    checkedText="Aktivní"
                                                    uncheckedText="Nektivní"
                                                    isChecked={!isDisabled}
                                                    onChange={() => {
                                                        toggleItemDisabled(
                                                            id,
                                                            isDisabled,
                                                        );
                                                    }}
                                                />
                                            </StyledSwitchContainer>
                                        ),
                                    },
                                ]}
                                rowHighlighting={{
                                    isFaded: ({ isDisabled }) => isDisabled,
                                }}
                                isHeadersShown={i === 0}
                            />
                        </Fragment>
                    ) : null;
                })}

                <StyledErrorMessage>
                    {amount && amount < minAmount
                        ? fillStringVariables(
                              "Snižte prosím počet nebo ceny položek, anebo navyšte požadovanou částku (minimálně na {minAmount}).",
                              {
                                  minAmount: formatCurrency(
                                      minAmount,
                                      currencyCode,
                                  ),
                              },
                          )
                        : null}
                </StyledErrorMessage>

                <StyledCustomItemBlock>
                    <Text alignment="center">
                        Chcete si zážitek přizpůsobit vlastím představám?
                        Neváhejte a přijdete vlastní položku.
                    </Text>

                    <ButtonsGroup>
                        <Button
                            label="Přidat vlastní"
                            size="small"
                            color="green"
                            onClick={() => createModalRef?.current?.show()}
                            icon={{
                                name: "plus",
                                color: "white",
                            }}
                        />
                    </ButtonsGroup>
                </StyledCustomItemBlock>
            </StyledContainer>
        </Fragment>
    );
};
