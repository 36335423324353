import styled, { keyframes } from "styled-components";
import { Icon, Text, Title } from "../../../";

const gradientAnimation = (backgroundColors: string[] | undefined) => keyframes`
    0% {
        background-color: ${backgroundColors?.[0]};
    }
    33% {
        background-color: ${backgroundColors?.[1]};
    }
    66% {
        background-color: ${backgroundColors?.[2]};
    }
    100% {
        background-color: ${backgroundColors?.[0]};
    }
`;

export const StyledContainer = styled.section<{ backgroundColors?: string[] }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0;
    position: relative;

    &::after {
        content: "";
        width: 100vw;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: -9;

        animation-name: ${({ backgroundColors }) =>
            backgroundColors &&
            backgroundColors.length > 0 &&
            gradientAnimation(backgroundColors)};
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-fill-mode: forwards;
    }
`;

export const StyledItemsContainer = styled.div`
    width: 85%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 50px;

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        width: 100%;
    }
`;

export const StyledItem = styled.article<{ columnsCount?: number }>`
    flex-basis: ${({ columnsCount }) => `calc(90% / ${columnsCount || 2})`};
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        flex-basis: 100%;
    }
`;

export const StyledItemIcon = styled(Icon)`
    width: 60px;
    min-width: 60px;
    height: 60px;
    min-height: 60px;
    margin: -5px 0 20px 0;
`;

export const StyledItemLabel = styled(Title)`
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-style: ${({ theme }) =>
        // Fixes Playfair Display font in Safari when used "wedding" theme.
        theme.fonts.secondary.includes("Playfair Display") && "italic"};
    padding-left: 20px;
`;

export const StyledItemDescription = styled(Text)`
    padding-left: 20px;
`;
