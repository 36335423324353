import { ContentBlockRow } from "@gift/ui";

export const ContentBlock_HowItWorksDetail: React.FC = () => (
    <ContentBlockRow
        subheadline="Jak to funguje?"
        headline="Hladce, bezpečně a rychle – než řeknete ANO!"
        description="Moderní technologie mění svět i způsob výběru peněz pro novomanžele. Buďte u toho."
        items={[
            {
                description:
                    "1. Vyberte si přání, které chcete novomanželům splnit.",
                iconName: "airplaneTicket",
            },
            {
                description:
                    "2. Částku pošlete zabezpečeným převodem na jejich účet.",
                iconName: "shield",
            },
            {
                description:
                    "3. Stáhněte si krásný certifikát k vytištění s vaším jménem.",
                iconName: "certificate",
            },
        ]}
    />
);
