import { IEvent } from "@gift/types";
import { IModalRefProps, Modal, ShareEventBox } from "@gift/ui";
import { forwardRef, RefAttributes } from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
    margin: 25px 0 5px 0;
`;

export interface IProps {
    eventId: IEvent["id"];
}

export const Modal_EventShare: React.FC<
    IProps & RefAttributes<IModalRefProps>
> = forwardRef(({ eventId }, ref) => (
    <Modal ref={ref} width={500}>
        <StyledContainer>
            <ShareEventBox eventId={eventId} />
        </StyledContainer>
    </Modal>
));
