import { IEvent, IPayment } from "@gift/types";
import {
    EventPreview,
    Layout,
    Loader,
    PdfDocumentsList,
    Title,
} from "@gift/ui";
import { EventActions, log, PaymentActions, useCurrentPage } from "@gift/utils";
import { Fragment, useEffect, useState } from "react";

export const Page_CertificatesDownload: React.FC = () => {
    const {
        params: { id: paymentId },
    } = useCurrentPage({ title: "Vaše certifikáty" });

    const [event, setEvent] = useState<IEvent>();
    const [payment, setPayment] = useState<IPayment>();

    const isLoaded = payment && event;

    const getEventData = (eventId: string) => {
        EventActions.get(eventId)
            .then(setEvent)
            .catch(() => {
                log({
                    code: "EXCEPTION",
                    scope: Page_CertificatesDownload.name,
                    error: new Error("Event not found."),
                    data: {
                        payment,
                    },
                });
            });
    };

    const getPaymentData = () => {
        PaymentActions.get(paymentId)
            .then((payment) => {
                setPayment(payment);
                getEventData(payment.eventId);
            })
            .catch(() => {
                log({
                    code: "EXCEPTION",
                    scope: Page_CertificatesDownload.name,
                    error: new Error("Payment not found."),
                    data: {
                        id: paymentId,
                    },
                });
            });
    };

    useEffect(() => {
        getPaymentData();
    }, []);

    return (
        <Layout width="narrow">
            <Title level={1}>Vaše certifikáty</Title>

            {isLoaded ? (
                <Fragment>
                    <div style={{ marginBottom: 50 }}>
                        <EventPreview {...event} />
                    </div>

                    <PdfDocumentsList event={event} payment={payment} />
                </Fragment>
            ) : (
                <Loader />
            )}
        </Layout>
    );
};
