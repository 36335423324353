import { ERoutePaths } from "@gift/types";
import { Route } from "react-router-dom";
import { Page_PersonalDataProtection } from "./PersonalDataProtection";

export default [
    <Route
        path={ERoutePaths.PERSONAL_DATA_PROTECTION}
        element={<Page_PersonalDataProtection />}
    />,
];
