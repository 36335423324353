import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { App } from "./app/App";

ReactDOM.render(
    <StrictMode>
        <App />
    </StrictMode>,
    document.querySelector("#app"),
);
