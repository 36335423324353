import { ICartItem } from "@gift/types";
import {
    calcCartItemsPricesSum,
    calcCartItemsQuantitySum,
    calcTemplateItemsAmount,
} from "@gift/utils";

export const processCart = (
    cart: any[],
    eventItems: any[],
    eventAmount: number,
) => {
    const { prices } = calcTemplateItemsAmount(eventItems, eventAmount, "CZK");

    const items: ICartItem[] =
        eventItems
            .map((item) => ({
                id: item.id,
                quantity: cart.find(({ id }) => item.id === id)?.quantity || 0,
                pricePerItem: prices.find(({ id }) => item.id === id)!.value,
            }))
            .filter(({ quantity }) => quantity > 0) || [];

    return {
        items,
        sums: {
            quantity: calcCartItemsQuantitySum(items),
            price: calcCartItemsPricesSum(items, prices),
        },
    };
};
