import { Button, Image, Link, Text, Title } from "@gift/ui";
import styled from "styled-components";

export const StyledContainer = styled.ul`
    width: 100%;
    box-shadow: 0 0 15px ${({ theme }) => theme.colors.grayLight};
    padding: 60px;
    margin-top: 20px;

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        padding: 10px;
    }
`;

export const StyledTitle = styled(Title)`
    font-size: 24px;
    padding: 0;
    margin: 0 0 20px 0;
`;

export const StyledItem = styled.li`
    color: gray;
    font-size: 14px;
    list-style: none;
    line-height: 200%;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grayLight};
    padding: 10px 0 20px 0;

    &:last-of-type {
        border-bottom: none;
    }
`;

export const StyledLabel = styled.p`
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
`;

export const StyledContent = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        flex-direction: column;
    }
`;

export const StyledImage = styled(Image)`
    width: 20%;
    aspect-ratio: 16/10;
    box-shadow: 0 0 15px ${({ theme }) => theme.colors.grayLight};

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        width: 100%;
    }
`;

export const StyledDescription = styled.p`
    flex-basis: 40%;
    margin-top: -5px;

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        padding: 20px 0;
    }
`;

export const StyledPlusMinusContainer = styled.div`
    display: flex;
`;

export const StyledInStockLabel = styled.p`
    flex-basis: 150px;
    text-align: right;
    padding: 4px 20px 0 0;
`;

export const StyledPlusMinusButton = styled(Button)`
    width: 35px;
    min-width: 35px;
    height: 35px;
`;

export const StyledPlusMinusLabel = styled.p`
    min-width: 40px;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    padding-top: 4px;
`;

export const StyledAmountLabel = styled.p`
    width: 70px;
    font-weight: 600;
    white-space: nowrap;
    margin-top: 4px;
    margin-left: 15px;
`;

export const StyledSummaryContainer = styled.div`
    text-align: right;
    margin-top: 60px;
`;

export const StyledSummaryContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const StyledSumAmount = styled(Text)`
    color: ${({ theme }) => theme.colors.blueDark};
    font-size: 38px;
    font-weight: 600;
    margin-left: 15px;
`;

export const StyledPreviewLink = styled(Link)`
    position: absolute;
    right: 45px;
    bottom: 0;

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        position: static;
        margin-top: 15px;
    }
`;
