import { Button, Carousel } from "@gift/ui";
import { useNavigation, useTranslation } from "@gift/utils";
import React, { Fragment } from "react";
import Image3 from "../../../../assets/header-images/01.png";
import Image4 from "../../../../assets/header-images/02.png";
import {
    StyledContainer,
    StyledImage,
    StyledItem,
    StyledSpacer,
    StyledText,
    StyledTextContainer,
    StyledTitle,
} from "./Header.styled";

export const Header: React.FC = () => {
    const t = useTranslation();
    const navigateTo = useNavigation();

    return (
        <Fragment>
            <StyledContainer>
                <Carousel
                    transitionTime={2000}
                    isInfiniteLoop
                    isAutoPlayOn
                    slides={[
                        {
                            backgroundColor: "#F6F3EA",
                            renderer: () => (
                                <StyledItem>
                                    <StyledTextContainer>
                                        <StyledTitle level={1} alignment="left">
                                            Svatební dar, který plní sny
                                        </StyledTitle>

                                        <StyledText>
                                            Láska je k nezaplacení, ale dokonalé
                                            líbánky si koupit můžete. Stačí
                                            velké přání rozdělit na malé dílky a
                                            začnou se dít věci. Když se zapojí
                                            všichni hosté, funguje to nejlépe.
                                            Objevte první službu, která peněžním
                                            dárkům vdechuje emoce a styl.
                                        </StyledText>

                                        <Button
                                            label={t(
                                                "buttonLabel_createProject",
                                            )}
                                            color="accentSecondary"
                                            size="big"
                                            onClick={navigateTo.eventCreate}
                                        />
                                    </StyledTextContainer>

                                    <StyledImage src={Image3} />
                                </StyledItem>
                            ),
                        },
                        {
                            backgroundColor: "#E4E0EB",
                            renderer: () => (
                                <StyledItem>
                                    <StyledTextContainer>
                                        <StyledTitle level={1} alignment="left">
                                            Proměňte přání ve skutečnost
                                        </StyledTitle>

                                        <StyledText>
                                            Nejlepším svatebním darem jsou
                                            peníze, které pomohou splnit velký
                                            sen. Objevte první službu, která
                                            peněžním dárkům vdechuje emoce a
                                            styl. Když se zapojí všichni hosté,
                                            funguje to nejlépe.
                                        </StyledText>

                                        <Button
                                            label={t(
                                                "buttonLabel_createProject",
                                            )}
                                            color="accentSecondary"
                                            size="big"
                                            onClick={navigateTo.eventCreate}
                                        />
                                    </StyledTextContainer>

                                    <StyledImage src={Image4} />
                                </StyledItem>
                            ),
                        },
                    ]}
                />
            </StyledContainer>

            <StyledSpacer />
        </Fragment>
    );
};
