import styled from "styled-components";

export type TLayoutWidth = "narrow" | "medium" | "wide";

export const StyledContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const LayoutMargin = styled.div<{ width?: TLayoutWidth }>`
    width: 100%;
    max-width: ${({ width }) =>
        width === "narrow" ? 700 : width === "medium" ? 900 : 1200}px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
`;

export const StyledContent = styled.section`
    flex-basis: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.08);

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-top: 60px;
    }
`;
