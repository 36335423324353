module.exports = {
    name: "Dárek na svatbu",
    nameShort: "Dárek na svatbu",
    description:
        "Jsou pro vás nejlepším svatebním darem peníze? Ale jak si o ně říct? Jděte na to elegantně, zapojte všechny hosty a získejte více.",
    keywords:
        "svatba, dar, svatební, dárek, sbírka, přání, crowdfunding, wedding, wish, cash, money, darkomat",
    backgroundColor: "#E4E0EB",
    accentColor: "#21b7cd",
    developerName: "BrightWorks s.r.o.",
    developerUrl: "https://www.dareknasvatbu.cz/",
    supportEmailAddress: "info@dareknasvatbu.cz",
    csasPaymentShopId: 283518,
    csasPaymentSignKey: "83038776131935975551",
    logtailTokenDev: "W46iktu6JLuNiK85LQckhpob",
    logtailTokenProd: "7E7mbi5uBNqVXemz5mLnD5wC",
    logRocketToken: "brightworks/spolecny-darek",
    caching: true,
};
