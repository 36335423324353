import { IContext } from "@gift/types";
import { Context } from "@gift/utils";
import { CookieBanner as CookieBannerCore } from "@honzachalupa/ui";
import { useContext } from "react";

export const CookieBanner: React.FC = () => {
    const { setIsCookiesAllowed } = useContext<IContext>(Context);

    return (
        <CookieBannerCore
            headline="Souhlasíte s použitím analytických cookies?"
            content="Tyto soubory používáme k měření a analýze návštěvnosti
                našich webových stránek (množství návštěvníků, zobrazené
                stránky, průměrná doba prohlížení atd.), což nám pomáhá
                vylepšovat jejich fungování a vyvíjet pro vás nové funkce.
                Souhlasem nám pomáháte získat cenná data o tom, jak naše
                stránky užíváte. Díky tomu náš web funguje lépe a vy si
                můžete užít kvalitnější uživatelský zážitek."
            agreeButtonText="Souhlasím"
            disagreeButtonText="Nesouhlasím"
            onSubmit={setIsCookiesAllowed}
        />
    );
};
