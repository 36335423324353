import styled from "styled-components";
import { Text } from "../../../";

export const StyledItemsContainer = styled.ul`
    width: 100%;
    border-top: 1px solid ${({ theme }) => theme.colors.grayLight};
    border-bottom: 1px solid ${({ theme }) => theme.colors.grayLight};
    padding: 15px;
`;

export const StyledItem = styled.li`
    height: 30px;
    color: gray;
    font-size: 14px;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledItemsFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
`;

export const StyledText = styled(Text)`
    padding: 0;
    margin: 0;
`;

export const StyledPrice = styled.span`
    color: ${({ theme }) => theme.colors.blueDark};
    font-size: 23px;
    font-weight: 600;
    margin-left: 20px;
`;
