import { ContentBlockGrid } from "@gift/ui";

export const ContentBlock_Benefits: React.FC = () => (
    <ContentBlockGrid
        subheadline="Stylové řešení svatebního daru"
        headline="Výhody pro hosty i novomanžele"
        description="V čem je kouzlo? I malé částky dokážou splnit velký sen. A všichni hosté můžou být součástí."
        items={[
            {
                label: "Opravdový užitek",
                description:
                    "Předejděte nevhodným a duplicitním dárkům a pomozte hostům potěšit vás dárkem, který pro vás bude mít největší hodnotu.",
                iconName: "airplaneTicket",
            },
            {
                label: "Žádné nákupní seznamy",
                description:
                    "Vytváření seznamů, když už vše máte? Hosté, běhající po obchodech? Zjednodušte si život a využijte každou korunu na maximum.",
                iconName: "list",
            },
            {
                label: "Peníze v bezpečí",
                description:
                    "Kam na svatbě s obálkami? S naší službou máte o starost méně. Peníze jsou v bezpečí na vašem bankovním účtu.",
                iconName: "shield",
            },
            {
                label: "Krásný certifikát",
                description:
                    "Žádný host nepřijde na svatbu s prázdnou. Každý získá certifikát k vytištění se jménem, částkou a uvedením konkrétního dárku.",
                iconName: "certificate",
            },
        ]}
    />
);
