import { ITheme } from "@honzachalupa/ui";

const colors: ITheme["colors"] = {
    accentPrimary: "#0c2461",
    accentSecondary: "#ff6348",
    black: "black",
    white: "white",
    grayLight: "lightgray",
    grayDark: "#1c202a",
    green: "#1fb141",
    red: "#e60000",
    blueLight: "#4dc5d7",
    blueDark: "#21b7cd",
};

export const themeGeneral: ITheme = {
    colors,
    background: colors.white,
    fonts: {
        secondary: "'Josefin Sans', sans-serif",
        primary: "'Barlow', sans-serif",
    },
    fontColors: {
        black: colors.black,
        white: colors.white,
        faded: colors.grayDark,
    },
    breakpoints: {
        sm: "600px",
        md: "1100px",
    },
    footer: {
        light: "#b7e1de",
        dark: "#9dcfc6",
    },
};
