import styled from "styled-components";
import { Icon, Text, Title } from "../../../";

export const StyledContainer = styled.section<{ backgroundColor?: string }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0;
    position: relative;

    &::after {
        content: "";
        width: 100vw;
        height: 100%;
        background-color: ${({ backgroundColor }) => backgroundColor};
        position: absolute;
        top: 0;
        z-index: -9;
    }
`;

export const StyledItemsContainer = styled.div`
    width: 85%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 50px;

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        width: 100%;
    }
`;

export const StyledItem = styled.article<{ columnsCount?: number }>`
    flex-basis: ${({ columnsCount }) => `calc(90% / ${columnsCount || 2})`};
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        flex-basis: 100%;
    }
`;

export const StyledItemIcon = styled(Icon)`
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
`;

export const StyledItemLabel = styled(Title)`
    text-align: center;
`;

export const StyledItemDescription = styled(Text)`
    text-align: center;
    padding: 0 10px;
`;
