import { IEvent, IFormInput } from "@gift/types";
import { Form } from "@gift/ui";
import { useTranslation } from "@gift/utils";
import { useMemo } from "react";

export interface IFormData {
    name: string;
    amount: number;
    durationDays: number;
    description: string;
    bban: string;
}

export interface IProps {
    event: IEvent;
    onSubmit: (formData: IFormData) => void;
}

export const Form_EventEdit: React.FC<IProps> = ({ event, onSubmit }) => {
    const t = useTranslation();

    return (
        <Form
            inputs={useMemo<IFormInput[]>(
                () =>
                    event
                        ? [
                              {
                                  id: "name",
                                  label: t("inputLabel_projectName"),
                                  value: event.name,
                                  controlType: "input",
                                  rules: {
                                      required: true,
                                  },
                              },
                              {
                                  id: "description",
                                  label: t("inputLabel_description"),
                                  value: event.description,
                                  controlType: "input",
                              },
                              {
                                  id: "amount",
                                  label: t("inputLabel_goalAmount"),
                                  value: event.amount,
                                  controlType: "input",
                                  inputType: "number",
                                  isReadOnly: true,
                              },
                              {
                                  id: "durationDays",
                                  label: t("inputLabel_vacationDuration"),
                                  value: event.durationDays,
                                  controlType: "input",
                                  inputType: "number",
                                  isReadOnly: true,
                              },
                              {
                                  id: "bban",
                                  label: t("inputLabel_bankAccountNumber"),
                                  value: event.bban,
                                  controlType: "input",
                                  isReadOnly: true,
                              },
                          ]
                        : [],
                [t, event],
            )}
            buttonsRenderer={(submit) => [
                {
                    label: "Uložit změny",
                    onClick: submit,
                },
            ]}
            onSubmit={onSubmit}
        />
    );
};
