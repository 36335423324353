import { PDFDownloadLink } from "@react-pdf/renderer";
import styled from "styled-components";
import { ButtonsGroup } from "../../../";

export const StyledItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;

    &:last-of-type {
        margin-bottom: 0;
    }
`;

export const StyledPreviewContainer = styled.div`
    box-shadow: 0 0 15px ${({ theme }) => theme.colors.grayLight};
`;

export const StyledButtonsGroup = styled(ButtonsGroup)`
    margin-top: 20px;
`;

export const StyledDownloadButton = styled(PDFDownloadLink)`
    background-color: ${({ theme }) => theme.colors.accentSecondary};
    color: white;
    font-size: 17px;
    font-weight: 600;
    white-space: nowrap;
    border: none;
    box-shadow: 0 0 15px ${({ theme }) => theme.colors.grayLight};
    padding: 10px 20px;
    transition: 200ms background-color, color, opacity;

    &:hover,
    &:focus,
    &:active {
        border: none;
        outline: none;
    }
`;
