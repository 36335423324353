import { log, Storage, useTranslation } from "@gift/utils";
import {
    forwardRef,
    Fragment,
    RefAttributes,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import { useTheme } from "styled-components";
import {
    IGalleryImage,
    ImageUploaderGallery,
} from "../../atoms/ImageUploaderGallery";
import {
    StyledContainer,
    StyledFileSelector,
    StyledFileSelectorDescription,
    StyledFileSelectorIcon,
    StyledFileSelectorRef,
    StyledImagePreview,
    StyledResetButton,
} from "./ImageUploader.styled";

export interface IProps {
    eventId: string;
    templateId: string;
    onChange: () => void;
}

export interface IImageUploaderRefProps {
    getImagePath: () => {
        status: string;
        imagePath: string | null;
    };
}

export const ImageUploader: React.FC<
    IProps & RefAttributes<IImageUploaderRefProps>
> = forwardRef(({ eventId, templateId, onChange }, ref) => {
    const theme = useTheme();
    const t = useTranslation();

    const fileSelectorRef = useRef<HTMLInputElement>(null);

    const [imageSource, setImageSource] = useState<"device" | "gallery">(
        "device",
    );
    const [deviceImageFile, setDeviceImageFile] = useState<File | undefined>();
    const [galleryImage, setGalleryImage] = useState<IGalleryImage>({
        path: "",
        url: "",
    });
    const [imageUrl, setImageUrl] = useState<string | undefined>();

    const uploadImage = () => {
        if (imageSource === "device") {
            if (deviceImageFile?.name) {
                const filePath = `event-images/${eventId}.${
                    deviceImageFile.name.match(/\.(.[a-z]*)$/)![1]
                }`;

                Storage.upload(filePath, deviceImageFile!).catch((error) => {
                    log({
                        code: "EXCEPTION",
                        scope: ImageUploader.name,
                        error,
                    });
                });

                return {
                    status: "success",
                    imagePath: filePath,
                };
            }

            return {
                status: "failed",
                imagePath: null,
            };
        }

        return {
            status: "success",
            imagePath: galleryImage.path,
        };
    };

    const handleReset = () => {
        setImageSource("device");
        setDeviceImageFile(undefined);
        setImageUrl(undefined);
        setGalleryImage({
            path: "",
            url: "",
        });
    };

    useEffect(() => {
        if (imageSource === "device") {
            if (deviceImageFile) {
                setImageUrl(URL.createObjectURL(deviceImageFile));
            }
        } else {
            setImageUrl(galleryImage.url);
        }

        onChange();
    }, [deviceImageFile, galleryImage]);

    useImperativeHandle(
        ref,
        (): IImageUploaderRefProps => ({
            getImagePath: uploadImage,
        }),
    );

    return (
        <StyledContainer>
            <StyledFileSelector
                onClick={() => fileSelectorRef.current?.click()}
            >
                {imageUrl ? (
                    <Fragment>
                        <StyledImagePreview src={imageUrl} />

                        <StyledResetButton
                            label={t("buttonLabel_uploadFromDevice")}
                            icon={{
                                name: "cloud",
                                color: "white",
                            }}
                            color="accentSecondary"
                            onClick={handleReset}
                        />
                    </Fragment>
                ) : (
                    <Fragment>
                        <StyledFileSelectorIcon
                            name="cloud"
                            color={theme.colors.accentSecondary}
                        />

                        <StyledFileSelectorDescription>
                            {t("inputDescription_image")}
                        </StyledFileSelectorDescription>
                    </Fragment>
                )}
            </StyledFileSelector>

            <StyledFileSelectorRef
                ref={fileSelectorRef}
                type="file"
                accept="image/png,image/jpg,image/jpeg"
                onChange={(e) => {
                    if (e.target.files?.[0]) {
                        setDeviceImageFile(e.target.files?.[0]);
                    }

                    setImageSource("device");
                }}
            />

            <ImageUploaderGallery
                templateId={templateId}
                onChange={(galleryImage) => {
                    setGalleryImage(galleryImage);
                    setImageSource("gallery");
                }}
            />
        </StyledContainer>
    );
});
