import styled from "styled-components";
import { Button, ButtonsGroup } from "../../../";

export const StyledToggleExpandButton = styled(Button)`
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 99;
`;

export const StyledContainer = styled.div`
    width: 100vw;
    height: 60px;
    background-color: ${({ theme }) => theme.colors.grayLight};
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 99;
`;

export const StyledButtonsGroup = styled(ButtonsGroup)`
    width: auto;
`;
