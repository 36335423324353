import { EEventStates, IEvent } from "@gift/types";
import { calcContributionsAmountSum } from "@gift/utils";
import {
    addUnitLabel,
    fillStringVariables,
    formatCurrency,
} from "@honzachalupa/utils";
import moment from "moment";
import {
    StyledContent,
    StyledDueDate,
    StyledProgressBar,
    StyledText,
    StyledTextBig,
    StyledTextMedium,
} from "./ProgressBox.styled";

export interface IProps {
    amount: IEvent["amount"];
    currencyCode: IEvent["currencyCode"];
    contributions: IEvent["contributions"];
    dueDate: IEvent["dueDate"];
    state: IEvent["state"];
    className?: string;
    isCondensed?: boolean;
    isDueDateShown?: boolean;
}

export const ProgressBox: React.FC<IProps> = ({
    amount,
    currencyCode,
    contributions,
    dueDate,
    state,
    className,
    isCondensed,
    isDueDateShown = true,
}) => {
    const paidAmount = calcContributionsAmountSum(contributions);
    const percents = Math.round((paidAmount / amount) * 100);
    const dueDays = moment(dueDate).diff(moment(), "days") + 1;

    return (
        <div className={className}>
            <StyledContent>
                <div>
                    <StyledTextMedium>Splněno</StyledTextMedium>
                    <StyledText>{percents}%</StyledText>
                </div>

                <div style={{ textAlign: "right" }}>
                    <StyledTextMedium>Cíl</StyledTextMedium>
                    <StyledText>
                        {formatCurrency(amount, currencyCode)}
                    </StyledText>
                </div>
            </StyledContent>

            <StyledProgressBar progress={percents} isCondensed={isCondensed} />

            <StyledContent>
                <div>
                    <StyledText>Vybráno</StyledText>
                    <StyledTextBig>
                        {formatCurrency(paidAmount, currencyCode)}
                    </StyledTextBig>
                </div>

                <div style={{ textAlign: "right" }}>
                    <StyledText>Účastníků</StyledText>
                    <StyledTextBig>{contributions.length}</StyledTextBig>
                </div>
            </StyledContent>

            {isDueDateShown && (
                <StyledDueDate>
                    {state === EEventStates.CREATED
                        ? fillStringVariables(
                              "Ke splnění zbývá {dueDaysWithUnit}.",
                              {
                                  dueDaysWithUnit: addUnitLabel(
                                      dueDays,
                                      "days",
                                  ),
                              },
                          )
                        : "Přání bylo již naplněno."}
                </StyledDueDate>
            )}
        </div>
    );
};
