import { ERoutePaths, IMail, IPayment } from "@gift/types";
import {
    fillStringVariables,
    formatCurrency,
    TCurrencyCodes,
} from "@honzachalupa/utils";
import { config } from "..";

const signature = `<br /><br />${config.name}<br />${config.developerUrl}`;

export const MailTemplates: { [key: string]: (props: any) => IMail } = {
    contactUs: ({
        from,
        name,
        message,
    }: {
        from: string;
        name: string;
        message: string;
    }) => ({
        to: config.supportEmailAddress,
        from,
        subject: "Dotaz z webu",
        content: "Dotaz od uživatele {name}.<br /><br />Zpráva:<br />{message}"
            .replace("{name}", name)
            .replace("{message}", message),
    }),
    invoice: ({
        to,
        amount,
        currencyCode,
    }: {
        to: string;
        amount: number;
        currencyCode: TCurrencyCodes;
    }) => ({
        to,
        from: config.supportEmailAddress,
        subject: "Faktura",
        content:
            "Dobrý den,<br />zasíláme vám fakturu na částku {amount}.".replace(
                "{amount}",
                formatCurrency(amount, currencyCode),
            ) + signature,
    }),
    paymentConfirmation: ({
        to,
        paymentId,
    }: {
        to: string;
        paymentId: IPayment["id"];
    }) => {
        const path = ERoutePaths.PAYMENT_CERTIFICATES_DOWNLOAD.replace(
            ":id",
            paymentId.toString(),
        );
        const url = `${window.location.origin}${path}`;

        return {
            to,
            from: config.supportEmailAddress,
            subject: "Zasíláme vám zakoupené certifikáty",
            content:
                fillStringVariables(
                    'Dobrý den,<br />zasíláme vám zakoupené certifikáty.<br /><br />Zde si je můžete stáhnout: <a href="{downloadUrl}">{downloadUrl}</a>',
                    {
                        downloadUrl: url,
                    },
                ) + signature,
        };
    },
};
