import { IModalRefProps, Modal } from "@gift/ui";
import { useTranslation } from "@gift/utils";
import { forwardRef, RefAttributes } from "react";
import { Form_SignUp } from "../forms/SignUp";

export interface IProps {
    handleCreateEvent: () => () => void;
}

export const Modal_SignUp: React.FC<IProps & RefAttributes<IModalRefProps>> =
    forwardRef(({ handleCreateEvent }, ref) => {
        const t = useTranslation();

        return (
            <Modal ref={ref} height={800}>
                <h1>{t("headline_signUp")}</h1>

                <p>
                    Abyste mohli vytvoření přání dokončit, zaregistrujte se
                    prosím.
                </p>

                <Form_SignUp handleCreateEvent={handleCreateEvent} />
            </Modal>
        );
    });
