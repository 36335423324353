import { IFormInput } from "@gift/types";
import { useTranslation } from "@gift/utils";
import { Form as FormCore } from "@honzachalupa/ui";
import { forwardRef } from "react";
import { IButtonProps } from "../../../";

export interface IFormData {
    [key: string]: IFormInput["value"] | Array<IFormInput["value"]>;
}

export interface IFormRefProps {
    validate: () => void;
}

export interface IProps {
    inputs: (IFormInput | null)[];
    buttonsRenderer?: (onSubmit: any) => IButtonProps[];
    className?: string;
    onChange?: (event: any) => void;
    onSubmit?: (formData: any) => void;
}

export const Form = forwardRef((props: IProps, ref) => {
    const t = useTranslation();

    return (
        <FormCore
            ref={ref}
            validationTexts={{
                isRequired: t("formValidationMessage_isRequired"),
                maxLength: t("formValidationMessage_maxLength"),
                minLength: t("formValidationMessage_minLength"),
                pattern: t("formValidationMessage_pattern"),
            }}
            {...props}
        />
    );
});
