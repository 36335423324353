import { EInvoiceStateLabels, EInvoiceStates, IInvoice } from "@gift/types";
import { Layout, List, Title } from "@gift/ui";
import {
    InvoicesActions,
    MailActions,
    MailTemplates,
    useCurrentPage,
} from "@gift/utils";
import { formatCurrency } from "@honzachalupa/utils";
import { useEffect, useState } from "react";

export const Page_AdminstrationInvoices: React.FC = () => {
    const { navigateTo } = useCurrentPage({
        title: "Faktury",
        isAnalyticsDisabled: true,
    });

    const [invoices, setInvoices] = useState<IInvoice[]>([]);

    const setInvoiceState = (id: IInvoice["id"], state: EInvoiceStates) => {
        InvoicesActions.setState(id, state);
    };

    const handleSendInvoice = ({
        id,
        emailAddress,
        amount,
        currencyCode,
    }: IInvoice) => {
        MailActions.send(
            // @ts-ignore
            MailTemplates.invoice({
                to: emailAddress,
                amount,
                currencyCode,
            }),
        );

        setInvoiceState(id, EInvoiceStates.ISSUED);
    };

    useEffect(() => {
        const unsubscribe = InvoicesActions.searchAsync(setInvoices);

        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <Layout>
            <Title level={1}>Faktury</Title>

            <List
                data={invoices}
                columns={[
                    {
                        id: "amount",
                        label: "Částka",
                        renderer: ({ amount, currencyCode }: IInvoice) =>
                            formatCurrency(amount, currencyCode),
                    },
                    {
                        id: "emailAddress",
                        label: "E-mailová adresa",
                    },
                    {
                        id: "state",
                        label: "Stav",
                        renderer: ({ state }: IInvoice) =>
                            EInvoiceStateLabels[state],
                    },
                ]}
                actions={[
                    {
                        label: "Zobrazit událost",
                        onClick: ({ eventId }: IInvoice) => {
                            navigateTo.eventDetail(eventId);
                        },
                    },
                    {
                        label: "Odeslat fakturu",
                        isHidden: ({ state }: IInvoice) =>
                            state !== EInvoiceStates.CREATED,
                        onClick: handleSendInvoice,
                    },
                    {
                        label: "Označit jako nezaplacenou",
                        isHidden: ({ state }: IInvoice) =>
                            state !== EInvoiceStates.PAID,
                        onClick: ({ id }: IInvoice) => {
                            setInvoiceState(id, EInvoiceStates.CREATED);
                        },
                    },
                    {
                        label: "Označit jako zaplacenou",
                        isHidden: ({ state }: IInvoice) =>
                            state === EInvoiceStates.PAID,
                        onClick: ({ id }: IInvoice) => {
                            setInvoiceState(id, EInvoiceStates.PAID);
                        },
                    },
                ]}
            />
        </Layout>
    );
};
