import { EEventStates, IEvent, ISignedUser } from "@gift/types";
import {
    Button,
    DevBar,
    IModalRefProps,
    Layout,
    Loader,
    MockAction,
    Text,
} from "@gift/ui";
import {
    EventActions,
    isEventFinalState,
    log,
    useCurrentPage,
    UsersActions,
    useTranslation,
} from "@gift/utils";
import { useEffect, useMemo, useRef, useState } from "react";
import { Modal_EventShare } from "../../../components/organisms/modals/EventShare";
import { Modal_HowItWorks } from "../../../components/organisms/modals/HowItWorks";
import { Author } from "./components/Author";
import { Catalog } from "./components/Catalog";
import {
    StyledHeader,
    StyledHeaderButtonsGroup,
    StyledHeaderContent,
    StyledHeaderContentContainerRight,
    StyledHeaderImage,
    StyledProgressBox,
    StyledTitle,
} from "./Detail.styled";

export const Page_EventDetail: React.FC = () => {
    const t = useTranslation();
    const {
        user,
        params: { id: eventId },
        navigateTo,
    } = useCurrentPage({ title: t("headline_contribute") });

    const howItWorksModalRef = useRef<IModalRefProps>(null);
    const howShareModalRef = useRef<IModalRefProps>(null);

    const [event, setEvent] = useState<IEvent>(undefined as unknown as IEvent);
    const [author, setAuthor] = useState<ISignedUser>();

    const isLoaded = useMemo(
        () =>
            event &&
            (event.state === EEventStates.CREATED ||
                isEventFinalState(event.state)),
        [event],
    );

    const isCurrentUserAuthor = useMemo(
        () => user?.id === event?.ownerId,
        [user, event],
    );

    useEffect(() => {
        const unsubscribe = EventActions.getAsync(
            eventId,
            setEvent,
            navigateTo.notFound,
        );

        return () => {
            unsubscribe();
        };
    }, [eventId]);

    useEffect(() => {
        if (event) {
            UsersActions.get(event.ownerId)
                .then((user) => {
                    setAuthor(user);
                })
                .catch((error) => {
                    log({
                        code: "EXCEPTION",
                        scope: Page_EventDetail.name,
                        error,
                    });
                });
        }
    }, [event, isCurrentUserAuthor]);

    return isLoaded ? (
        <Layout>
            <DevBar>
                <MockAction name="contribute" event={event} />
            </DevBar>

            <Modal_HowItWorks ref={howItWorksModalRef} />
            <Modal_EventShare ref={howShareModalRef} eventId={event.id} />

            <StyledHeader>
                <StyledTitle level={1} alignment="left">
                    {event.name}
                </StyledTitle>

                {!isEventFinalState(event.state) && (
                    <StyledHeaderButtonsGroup alignment="right">
                        {isCurrentUserAuthor && (
                            <Button
                                label={t("buttonLabel_edit")}
                                color="blue"
                                size="small"
                                icon={{
                                    name: "edit",
                                    color: "white",
                                }}
                                onClick={() => navigateTo.eventEdit(event.id)}
                            />
                        )}

                        {!isCurrentUserAuthor && (
                            <Button
                                label={t("buttonLabel_howItWorks")}
                                color="blue"
                                size="small"
                                icon={{
                                    name: "help",
                                    color: "white",
                                }}
                                onClick={() =>
                                    howItWorksModalRef.current?.show()
                                }
                            />
                        )}

                        <Button
                            label={t("buttonLabel_share")}
                            color="blue"
                            size="small"
                            icon={{
                                name: "share",
                                color: "white",
                            }}
                            onClick={() => howShareModalRef.current?.show()}
                        />
                    </StyledHeaderButtonsGroup>
                )}

                <StyledHeaderContent>
                    <StyledHeaderImage storagePath={event.image} />

                    <StyledHeaderContentContainerRight>
                        <StyledProgressBox
                            amount={event.amount}
                            currencyCode={event.currencyCode}
                            contributions={event.contributions}
                            dueDate={event.dueDate}
                            state={event.state}
                        />

                        {author && <Author {...author} />}
                    </StyledHeaderContentContainerRight>
                </StyledHeaderContent>
            </StyledHeader>

            {event.description && <Text>{event.description}</Text>}

            <Catalog
                event={event}
                onSubmit={(order) => navigateTo.eventCheckout(event.id, order)}
            />
        </Layout>
    ) : (
        <Layout>
            <Loader />
        </Layout>
    );
};
