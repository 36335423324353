import {
    EPaymentStates,
    IEvent,
    ITemplateConstructor,
    ITemplateItem,
} from "@gift/types";
import {
    convertBBANtoIBAN,
    fillTranslations,
    log,
    mockData,
    PaymentActions,
    TemplatesActions,
    useTranslation,
} from "@gift/utils";
import { DATE_FORMAT } from "@honzachalupa/utils";
import moment from "moment";
import { Button } from "../../../../";

interface IEventCreateStep1FormData {
    name: IEvent["name"];
    description: IEvent["description"];
    image: IEvent["image"];
    amount: IEvent["amount"];
    currencyCode: IEvent["currencyCode"];
    dueDate: IEvent["dueDate"];
    durationDays: IEvent["durationDays"];
    bban: IEvent["bban"];
    templateId: ITemplateConstructor["id"];
    items: ITemplateItem[];
}

interface IProps {
    name: "createEvent" | "contribute";
    event?: IEvent;
    setFormData?: (formData: IEventCreateStep1FormData) => void;
}

export const MockAction: React.FC<IProps> = ({ name, event, setFormData }) => {
    const t = useTranslation();

    const actions = {
        createEvent: async () => {
            const getRandomRange = (min: number, max: number) =>
                Math.round(Math.random() * (max - min) + min);

            const amount =
                Math.round(getRandomRange(10000, 200000) / 1000) * 1000;
            const durationDays = getRandomRange(5, 21);

            const template = (
                await TemplatesActions.search()
                    .then((templates) => fillTranslations(templates, t))
                    .catch((error) => {
                        log({
                            code: "EXCEPTION",
                            scope: MockAction.name,
                            error,
                        });
                    })
            )?.[0];

            if (template) {
                setFormData!({
                    name: "Mock event name",
                    description: "Mock event description",
                    image: "placeholder.jpeg",
                    templateId: template.id,
                    amount,
                    currencyCode: "CZK",
                    dueDate: moment().add(1, "week").format(DATE_FORMAT),
                    durationDays,
                    bban: "1015552996/6100",
                    items: [
                        {
                            id: 1,
                            label: "Mock item 1",
                            description: "",
                            image: "placeholder.jpeg",
                            category: "ENTERTAINMENT",
                            price: {
                                type: "FIXED",
                                values: {
                                    CZK: 5000,
                                    EUR: 250,
                                },
                            },
                            quantity: 4,
                            availableQuantity: 4,
                            isDefaultChecked: true,
                        },
                        {
                            id: 2,
                            label: "Mock item 2",
                            description: "Mock item description",
                            image: "placeholder.jpeg",
                            category: "TRANSPORT",
                            price: {
                                type: "PORTION",
                                value: 0.3,
                            },
                            quantity: 2,
                            availableQuantity: 2,
                            isDefaultChecked: true,
                        },
                    ],
                });
            }
        },
        contribute: async () => {
            const paymentId = Math.round(Math.random() * 10000000);
            const iban = await convertBBANtoIBAN(event!.bban);

            PaymentActions.create(paymentId, {
                ...mockData.payment,
                id: paymentId,
                eventId: event!.id,
                bban: event!.bban,
                iban,
            })
                .then(() => {
                    setTimeout(() => {
                        PaymentActions.updateState(
                            paymentId,
                            EPaymentStates.SUCCESS,
                        );
                    }, 5000);
                })
                .catch((error) => {
                    log({
                        code: "EXCEPTION",
                        scope: MockAction.name,
                        error,
                    });
                });
        },
    };

    return (
        <Button
            label={`Mock action: ${name}`}
            size="small"
            onClick={actions[name]}
        />
    );
};
