import { Link } from "@honzachalupa/ui";
import styled from "styled-components";
import { ButtonsGroup, Icon } from "../../../../../../";
import { NavigationItem } from "../components/NavigationItem";

export const StyledContainer = styled.nav`
    width: 100%;
    background-color: white;
    box-shadow: 0 0 5px ${({ theme }) => theme.colors.grayLight};
    margin: 0 -20px;
    position: fixed;
    z-index: 99;
`;

export const StyledButtonsContainer = styled.div<{
    alignment?: "right";
    isHidden?: boolean;
}>`
    width: 100%;
    display: flex;
    justify-content: ${({ alignment }) =>
        alignment === "right" ? "flex-end" : "space-between"};
    visibility: ${({ isHidden }) => isHidden && "hidden"};
`;

export const StyledIcon = styled(Icon)`
    width: 60px;
    padding: 15px;
`;

export const StyledCloseIcon = styled(Icon)`
    width: 70px;
    padding: 15px;
    position: relative;
    z-index: 9;
`;

export const StyledOverlay = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 8;
    display: flex;
    justify-content: center;
`;

export const StyledItemsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    z-index: 9;
`;

export const StyledNavigationItem = styled(NavigationItem)`
    width: 100%;
    color: ${({ theme }) => theme.fontColors.black};
`;

export const StyledButtonsGroup = styled(ButtonsGroup)`
    width: 200px;
    margin-top: 50px;
`;

export const StyledSignOutLink = styled(Link)`
    margin-top: 20px;
`;
