import { ERoutePaths } from "@gift/types";
import {
    Authentication,
    log,
    useNavigation,
    useTranslation,
} from "@gift/utils";
import { scrollToTop, useWindowSize } from "@honzachalupa/utils";
import { useTheme } from "styled-components";
import { View_Desktop } from "./views/Desktop";
import { View_Mobile } from "./views/Mobile";

export interface INavigationItems {
    top: {
        [key in
            | "administration"
            | "account"
            | "createEvent"
            | "signUp"
            | "signIn"
            | "signOut"]: {
            label: string;
            path?: string;
            onClick?: () => void;
        };
    };
    bottom: {
        label: string;
        path?: string;
        onClick?: () => void;
    }[];
}

export const Navigation: React.FC = () => {
    const navigateTo = useNavigation();
    const t = useTranslation();
    const theme = useTheme();
    const { width } = useWindowSize();

    const isDesktopScreen = width > parseInt(theme.breakpoints.md);

    const handleSignOut = () =>
        Authentication.signOut()
            .then(() => navigateTo.home())
            .catch((error: any) => {
                log({
                    code: "EXCEPTION",
                    scope: Navigation.name,
                    error,
                });
            });

    const items: INavigationItems = {
        top: {
            administration: {
                label: "Administrace",
                path: ERoutePaths.ADMINISTRATION,
            },
            account: {
                label: t("headline_myEvents"),
                path: ERoutePaths.ACCOUNT,
            },
            createEvent: {
                label: t("buttonLabel_createProject"),
                path: ERoutePaths.EVENT_CREATE,
            },
            signUp: {
                label: t("buttonLabel_signUp"),
                path: ERoutePaths.AUTHENTICATION_SIGN_UP,
            },
            signIn: {
                label: t("buttonLabel_signIn"),
                path: ERoutePaths.AUTHENTICATION_SIGN_IN,
            },
            signOut: {
                label: t("buttonLabel_signOut"),
                onClick: handleSignOut,
            },
        },
        bottom: [
            {
                label: t("buttonLabel_home"),
                onClick: () => {
                    navigateTo.home();
                    scrollToTop();
                },
            },
            {
                label: t("buttonLabel_howItWorks"),
                onClick: () => navigateTo.home("howItWorks"),
            },
            {
                label: "O nás",
                onClick: () => navigateTo.home("aboutUs"),
            },
            {
                label: "FAQ",
                onClick: () => navigateTo.home("faq"),
            },
            {
                label: t("buttonLabel_contact"),
                path: ERoutePaths.CONTACT_US,
            },
        ],
    };

    return isDesktopScreen ? (
        <View_Desktop items={items} />
    ) : (
        <View_Mobile items={items} />
    );
};
