import { ERoutePaths } from "@gift/types";
import { Route } from "react-router-dom";
import { Page_Administration } from "./pages/Index";
import { Page_AdminstrationInvoices } from "./pages/Invoices";

export const routes = [
    <Route
        path={ERoutePaths.ADMINISTRATION}
        element={<Page_Administration />}
    />,
    <Route
        path={ERoutePaths.ADMINISTRATION_INVOICES}
        element={<Page_AdminstrationInvoices />}
    />,
];
