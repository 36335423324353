import {
    IEvent,
    IFormInput,
    IPricesListItem,
    ITemplate,
    ITemplateConstructor,
    ITemplateItem,
} from "@gift/types";
import { Form, TemplateItemsList } from "@gift/ui";
import {
    fillTranslations,
    log,
    TemplatesActions,
    useTranslation,
} from "@gift/utils";
import { scrollToTop } from "@honzachalupa/utils";
import { useEffect, useMemo, useRef, useState } from "react";
import { Modal_CreateTemplateItem } from "../modals/CreateTemplateItem";

export interface IEventCreateStep1FormData {
    templateId: ITemplateConstructor["id"];
    amount: IEvent["amount"];
    currencyCode: IEvent["currencyCode"];
    durationDays: IEvent["durationDays"];
    items: ITemplateItem[];
}

export interface IProps {
    onSubmit: (formData: IEventCreateStep1FormData) => void;
}

export const Form_EventCreate_Step1: React.FC<IProps> = ({ onSubmit }) => {
    const t = useTranslation();
    const formRef = useRef<HTMLFormElement>();

    const [formData, setFormData] = useState<IEventCreateStep1FormData>();
    const [templates, setTemplates] = useState<ITemplate[]>([]);
    const [templateId, setTemplateId] = useState<ITemplate["id"]>();
    const [template, setTemplate] = useState<ITemplate>();
    const [templateConfiguration, setTemplateConfiguration] = useState<{
        items: ITemplateItem[];
        prices: IPricesListItem[];
        minAmount: number;
    }>({
        items: [],
        prices: [],
        minAmount: 0,
    });

    const getTemplates = () => {
        TemplatesActions.search()
            .then((templates) => {
                setTemplates(fillTranslations(templates, t));
            })
            .catch((error) => {
                log({
                    code: "EXCEPTION",
                    scope: Form_EventCreate_Step1.name,
                    error,
                });
            });
    };

    const handleSubmit = async ({
        templateId,
        amount,
        currencyCode,
        durationDays,
    }: IEventCreateStep1FormData) => {
        onSubmit({
            templateId,
            amount: Math.round(amount / 1000) * 1000,
            currencyCode: currencyCode || "CZK",
            durationDays,
            items: templateConfiguration.items,
        });
    };

    useEffect(() => {
        getTemplates();
    }, []);

    useEffect(() => {
        if (templates.length > 0) {
            // setTemplateId(templates[0].id);

            setTemplateId("honeymoon");
        }
    }, [templates]);

    useEffect(() => {
        if (templates.length > 0 && templateId) {
            setTemplate(templates.find(({ id }) => id === templateId));
        }
    }, [templates, templateId]);

    return (
        <Form
            ref={formRef}
            inputs={useMemo<IFormInput[]>(
                () =>
                    [
                        {
                            id: "amount",
                            label: t("inputLabel_goalAmount"),
                            placeholder: t("inputPlaceholder_goalAmount"),
                            controlType: "input",
                            inputType: "number",
                            rules: {
                                required: true,
                            },
                            /* containerStyle: {
                                width: "80%",
                            }
                            inputStyle: {
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                            }, */
                        },
                        /* {
                            id: "currencyCode",
                            label: "",
                            value:
                                getBrowserLanguage() === "cs" ? "CZK" : "EUR",
                            controlType: "select",
                            options: ECurrencies.map(({ id, symbol }) => ({
                                id,
                                label: symbol,
                            })),
                            rules: {
                                required: true,
                            },
                            containerStyle: {
                                width: "20%",
                            },
                            inputStyle: {
                                borderLeft: "none",
                                borderTopLeftRadius: 0,
                                borderBottomRLeftRadius: 0,
                            },
                        },
                        {
                            id: "templateId",
                            label: t("inputLabel_template"),
                            value: template?.id,
                            controlType: "select",
                            options: templates?.map(({ id, label }) => ({
                                id,
                                label,
                            })),
                        }, */
                        template?.id === "vacation" ||
                        template?.id === "honeymoon"
                            ? {
                                  id: "durationDays",
                                  label: t("inputLabel_vacationDuration"),
                                  controlType: "input",
                                  inputType: "number",
                                  rules: {
                                      required: true,
                                      validate: (value: number) => {
                                          if (value < 0) {
                                              return "Hodnota nesmí být nižší než nula.";
                                          }

                                          return null;
                                      },
                                  },
                              }
                            : (null as any),
                        template
                            ? {
                                  id: "items-list",
                                  label: t("inputLabel_itemsList"),
                                  controlType: "custom",
                                  children: (
                                      <TemplateItemsList
                                          template={template}
                                          amount={formData?.amount || 0}
                                          durationDays={
                                              formData?.durationDays || 0
                                          }
                                          // TODO: Make currencyCode dynamic
                                          currencyCode="CZK"
                                          renderNewItemModal={(
                                              ref,
                                              onSubmit,
                                          ) => (
                                              <Modal_CreateTemplateItem
                                                  ref={ref}
                                                  currencyCode="CZK"
                                                  onSubmit={(item) =>
                                                      onSubmit(item)
                                                  }
                                              />
                                          )}
                                          onChange={setTemplateConfiguration}
                                      />
                                  ),
                              }
                            : (null as any),
                    ].filter(Boolean),
                [
                    template,
                    formData?.amount,
                    formData?.durationDays,
                    templateConfiguration.minAmount,
                ],
            )}
            buttonsRenderer={(submit) => [
                {
                    label: t("buttonLabel_continue"),
                    isDisabled:
                        (formData?.amount || -1) <
                        templateConfiguration.minAmount,
                    onClick: submit,
                },
            ]}
            onChange={setFormData}
            onSubmit={(formData) => {
                handleSubmit({
                    ...formData,
                    templateId: "honeymoon",
                });

                scrollToTop();
            }}
        />
    );
};
