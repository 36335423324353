import { IFormInput } from "@gift/types";
import { Form } from "@gift/ui";
import {
    MailActions,
    MailTemplates,
    useAuthentication,
    useNavigation,
} from "@gift/utils";
import { useMemo } from "react";

interface IFormData {
    name: string;
    emailAddress: string;
    message: string;
}

export const Form_ContactUs: React.FC = () => {
    const { user } = useAuthentication();
    const navigateTo = useNavigation();

    const handleSubmit = ({ name, emailAddress, message }: IFormData) => {
        MailActions.send(
            MailTemplates.contactUs({
                from: emailAddress,
                name,
                message,
            }),
        ).then(() => {
            alert("Zpráva byla odeslána");

            navigateTo.home();
        });
    };

    return (
        <Form
            inputs={useMemo<IFormInput[]>(
                () => [
                    {
                        id: "name",
                        label: "Jméno",
                        value: user
                            ? `${user.firstName} ${user.lastName}`
                            : undefined,
                        controlType: "input",
                        rules: {
                            required: true,
                        },
                        isReadOnly: !!(user?.firstName || user?.lastName),
                    },
                    {
                        id: "emailAddress",
                        label: "E-mailová adresa",
                        value: user?.emailAddress,
                        controlType: "input",
                        inputType: "email",
                        rules: {
                            required: true,
                        },
                        isReadOnly: !!user?.emailAddress,
                    },
                    {
                        id: "message",
                        label: "Zpráva",
                        controlType: "textarea",
                        rules: {
                            required: true,
                        },
                    },
                ],
                [user],
            )}
            buttonsRenderer={(submit) => [
                {
                    label: "Odeslat",
                    onClick: submit,
                },
            ]}
            onSubmit={handleSubmit}
        />
    );
};
