import { IContext } from "@gift/types";
import { GlobalStyle, useThemeSelector } from "@gift/ui";
import { Context } from "@gift/utils";
import React, { ReactNode } from "react";
import { ThemeProvider } from "styled-components";

interface IProps {
    context: IContext;
    children: ReactNode;
}

export const ProvidersWrapper: React.FC<IProps> = ({ context, children }) => {
    const theme = useThemeSelector(context.theme);

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle
                theme={theme}
                isScrollDisabled={context.isScrollDisabled}
            />

            <Context.Provider value={context}>{children}</Context.Provider>
        </ThemeProvider>
    );
};
