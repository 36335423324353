import {
    EEventStateLabels,
    EEventStates,
    ERoleLabels,
    ERoles,
    IEvent,
    ISignedUser,
} from "@gift/types";
import { Button, ButtonsGroup, Layout, List, Title } from "@gift/ui";
import {
    calcContributionsAmountSum,
    EventActions,
    useCurrentPage,
    UsersActions,
    useTranslation,
} from "@gift/utils";
import {
    DATE_TIME_FORMAT,
    formatCurrency,
    formatPhoneNumber,
} from "@honzachalupa/utils";
import moment from "moment";
import { useEffect, useState } from "react";

export const Page_Administration: React.FC = () => {
    const t = useTranslation();
    const { navigateTo } = useCurrentPage({
        title: "Administrace",
        isAnalyticsDisabled: true,
    });

    const [events, setEvents] = useState<IEvent[]>([]);
    const [users, setUsers] = useState<ISignedUser[]>([]);

    useEffect(() => {
        EventActions.search({
            orderBy: [["createdDate", "desc"]],
        }).then(setEvents);

        UsersActions.search({
            orderBy: [
                ["role", "asc"],
                ["lastName", "asc"],
            ],
        }).then(setUsers);
    }, []);

    return (
        <Layout>
            <Title level={1}>Administrace</Title>

            <ButtonsGroup>
                <Button
                    label="Faktury"
                    onClick={navigateTo.administrationInvoices}
                />
            </ButtonsGroup>

            <List
                headline="Události"
                data={events}
                columns={[
                    {
                        id: "createdDate",
                        label: "Datum vytvoření",
                        renderer: ({ createdDate }: IEvent) =>
                            moment(createdDate).format(DATE_TIME_FORMAT),
                    },
                    {
                        id: "owner",
                        label: "Vlastník",
                        renderer: ({ ownerId }: IEvent) => {
                            const user = users.find(({ id }) => id === ownerId);

                            return user
                                ? `${user.firstName} ${user.lastName}`
                                : null;
                        },
                    },
                    {
                        id: "name",
                        label: "Název",
                    },
                    {
                        id: "goal-amount",
                        label: "Vybráno",
                        renderer: ({ contributions, currencyCode }: IEvent) =>
                            formatCurrency(
                                calcContributionsAmountSum(contributions),
                                currencyCode,
                            ),
                    },
                    {
                        id: "amount",
                        label: "Cíl",
                        renderer: ({ amount, currencyCode }: IEvent) =>
                            formatCurrency(amount, currencyCode),
                    },
                    {
                        id: "state",
                        label: "Stav",
                        renderer: ({ state }: IEvent) =>
                            EEventStateLabels[state],
                    },
                ]}
                actions={[
                    {
                        label: t("buttonLabel_showMore"),
                        onClick: ({ id }: IEvent) => navigateTo.eventDetail(id),
                    },
                ]}
                rowHighlighting={{
                    isGreen: ({ state }: IEvent) =>
                        state === EEventStates.GOAL_AMOUNT_REACHED ||
                        state === EEventStates.ARCHIVED_BY_SYSTEM,
                }}
                isCountShown
            />

            <List
                headline="Uživatelé"
                data={users}
                columns={[
                    {
                        id: "name",
                        label: "Jméno",
                        renderer: ({ firstName, lastName }: ISignedUser) =>
                            `${firstName} ${lastName}`,
                    },
                    {
                        id: "emailAddress",
                        label: "E-mailová adresa",
                    },
                    {
                        id: "phoneNumber",
                        label: "Telefonní číslo",
                        renderer: ({ phoneNumber }: ISignedUser) =>
                            formatPhoneNumber(phoneNumber),
                    },
                    {
                        id: "role",
                        label: "Role",
                        renderer: ({ role }: ISignedUser) => ERoleLabels[role],
                    },
                ]}
                actions={[
                    {
                        label: "Napsat e-mail",
                        onClick: ({ emailAddress }: ISignedUser) =>
                            navigateTo.mailClient(emailAddress),
                    },
                ]}
                rowHighlighting={{
                    isGreen: ({ role }: ISignedUser) => role === ERoles.ADMIN,
                }}
                isCountShown
            />
        </Layout>
    );
};
