import { ISignedUser } from "@gift/types";
import {
    convertDocsToItems,
    convertDocToItem,
    TQuery,
} from "@honzachalupa/firebase";
import { Collections, Database } from "../helpers/firebase";
import { log } from "../helpers/logging";

export const UsersActions = {
    create: (
        id: string,
        {
            emailAddress,
            firstName,
            lastName,
            phoneNumber,
        }: {
            emailAddress: string;
            firstName: string;
            lastName: string;
            phoneNumber: string;
        },
    ) =>
        Database.set(Collections.users, id, {
            firstName,
            lastName,
            emailAddress,
            phoneNumber,
        }),

    update: (
        id: string,
        {
            firstName,
            lastName,
            emailAddress,
            phoneNumber,
        }: {
            emailAddress: string;
            firstName: string;
            lastName: string;
            phoneNumber: string;
        },
    ) =>
        Database.update(Collections.users, id, {
            firstName,
            lastName,
            emailAddress,
            phoneNumber,
        }),

    get: (id: string): Promise<ISignedUser> =>
        Database.get(Collections.users, id)
            .then(convertDocToItem)
            .catch((error) => {
                log({
                    code: "EXCEPTION",
                    scope: "UsersActions",
                    error,
                });

                return null as any;
            }),

    search: (query?: TQuery): Promise<ISignedUser[]> =>
        Database.search(Collections.users, query)
            .then(convertDocsToItems)
            .catch((error) => {
                log({
                    code: "EXCEPTION",
                    scope: "UsersActions",
                    error,
                });

                return [];
            }),
};
