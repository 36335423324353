import { Title } from "@gift/ui";
import styled from "styled-components";

export const StyledContainer = styled.div`
    width: 100%;
    height: 770px;
    display: flex;
    position: absolute;
    left: 0;

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        height: 400px;
    }
`;

export const StyledItem = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
`;

export const StyledSpacer = styled.div`
    width: 100%;
    height: 770px;
    visibility: hidden;

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        height: 400px;
    }
`;

export const StyledTextContainer = styled.div`
    flex-basis: 55%;

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        flex-basis: 100%;
    }
`;

export const StyledTitle = styled(Title)`
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-size: 4rem;
    font-style: ${({ theme }) =>
        // Fixes Playfair Display font in Safari when used "wedding" theme.
        theme.fonts.secondary.includes("Playfair Display") && "italic"};

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 3rem;
    }
`;

export const StyledText = styled.p`
    width: 100%;
    color: ${({ theme }) => theme.colors.accentPrimary};
    font-size: 1.2rem;
    line-height: 2rem;
    margin: 20px 0;

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 1rem;
        line-height: 1.5rem;
    }
`;

export const StyledImage = styled.img`
    flex-basis: 40%;
    max-height: 95%;
    align-self: flex-end;
    object-fit: contain;

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        display: none !important;
    }
`;
