import styled from "styled-components";

export const StyledContainer = styled.aside`
    border-top: 1px solid ${({ theme }) => theme.colors.grayLight};
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledContainerRight = styled.div`
    text-align: right;
`;

export const StyledName = styled.p`
    color: ${({ theme }) => theme.colors.accentSecondary};
    font-size: 1rem;
    font-weight: 600;
`;

export const StyledLabel = styled.p`
    font-size: 0.8rem;
`;
