import { ERoutePaths, IEvent } from "@gift/types";
import { IAbstractObject } from "@honzachalupa/utils";
import { useNavigate } from "react-router-dom";

export interface IUseNavigation {
    signUp: () => void;
    signIn: () => void;
    home: (scrollToRefName?: string) => void;
    eventCreate: (state?: IAbstractObject) => void;
    eventEdit: (eventId: IEvent["id"]) => void;
    eventDetail: (eventId: IEvent["id"]) => void;
    eventSummary: (eventId: IEvent["id"]) => void;
    eventCheckout: (eventId: IEvent["id"], state: IAbstractObject) => void;
    administration: () => void;
    administrationInvoices: () => void;
    contactUs: () => void;
    termsAndConditions: () => void;
    personalDataProtection: () => void;
    notFound: () => void;
    mailClient: (emailAddress: string) => void;
    path: (path: string) => void;
    url: (url: string) => void;
}

export const useNavigation = (): IUseNavigation => {
    const navigate = useNavigate();

    return {
        signUp: () => {
            navigate(ERoutePaths.AUTHENTICATION_SIGN_UP);
        },
        signIn: () => {
            navigate(ERoutePaths.AUTHENTICATION_SIGN_IN);
        },
        home: (scrollToRefName?: string) => {
            navigate(ERoutePaths.HOME, {
                state: {
                    scrollToRefName,
                },
            });
        },
        eventCreate: (state) => {
            navigate(ERoutePaths.EVENT_CREATE, { state });
        },
        eventEdit: (eventId) => {
            navigate(ERoutePaths.EVENT_EDIT.replace(":id", eventId));
        },
        eventDetail: (eventId) => {
            navigate(ERoutePaths.EVENT_DETAIL.replace(":id", eventId));
        },
        eventSummary: (eventId) => {
            navigate(ERoutePaths.EVENT_SUMMARY.replace(":id", eventId));
        },
        eventCheckout: (eventId, state) => {
            navigate(ERoutePaths.EVENT_CHECKOUT.replace(":id", eventId), {
                state,
            });
        },
        administration: () => {
            navigate(ERoutePaths.ADMINISTRATION);
        },
        administrationInvoices: () => {
            navigate(ERoutePaths.ADMINISTRATION_INVOICES);
        },
        contactUs: () => {
            navigate(ERoutePaths.CONTACT_US);
        },
        termsAndConditions: () => {
            navigate(ERoutePaths.TERMS_AND_CONDITIONS);
        },
        personalDataProtection: () => {
            navigate(ERoutePaths.PERSONAL_DATA_PROTECTION);
        },
        notFound: () => {
            navigate(ERoutePaths.NOT_FOUND);
        },
        mailClient: (emailAddress) => {
            document.location.href = `mailto:${emailAddress}`;
        },
        path: (path) => {
            navigate(path);
        },
        url: (url) => {
            window.location.href = url;
        },
    };
};
