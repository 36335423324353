import { isDev, useAuthentication } from "@gift/utils";
import { Fragment, ReactNode, useState } from "react";
import {
    StyledButtonsGroup,
    StyledContainer,
    StyledToggleExpandButton,
} from "./DevBar.styled";

interface IProps {
    children: ReactNode;
}

export const DevBar: React.FC<IProps> = ({ children }) => {
    const { user } = useAuthentication();

    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    return isDev ? (
        <div>
            {isExpanded ? (
                <Fragment>
                    <StyledContainer>
                        {user ? (
                            <p>
                                Logged as: {user?.firstName} {user?.lastName} (
                                {user?.emailAddress})
                            </p>
                        ) : (
                            <p>Not logged in.</p>
                        )}

                        <StyledButtonsGroup>{children}</StyledButtonsGroup>
                    </StyledContainer>

                    <StyledToggleExpandButton
                        label="Close"
                        color="red"
                        icon={{
                            name: "cross",
                            color: "white",
                        }}
                        onClick={() => setIsExpanded(false)}
                    />
                </Fragment>
            ) : (
                <StyledToggleExpandButton
                    label="Show actions"
                    icon={{
                        name: "hamburger",
                    }}
                    onClick={() => setIsExpanded(true)}
                />
            )}
        </div>
    ) : null;
};
