import styled from "styled-components";
import { ProgressBar, Text } from "../../../";

export const StyledContent = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const StyledProgressBar = styled(ProgressBar)<{ isCondensed?: boolean }>`
    margin: ${({ isCondensed }) =>
        isCondensed ? "0 0 10px 0" : "35px 0 45px 0"};
`;

export const StyledText = styled(Text)`
    font-size: 18px;
    padding: 0;
    margin-bottom: 20px;
`;

export const StyledTextMedium = styled.p`
    color: ${({ theme }) => theme.colors.accentSecondary};
    font-size: 24px;
    font-weight: 600;
    margin-top: -8px;
`;

export const StyledTextBig = styled.p`
    color: ${({ theme }) => theme.colors.blueDark};
    font-size: 38px;
    font-weight: 600;
    margin-top: -10px;
`;

export const StyledDueDate = styled(StyledText)`
    text-align: center;
    margin-top: 20px;
`;
