import { IEvent } from "@gift/types";
import { useNavigation, useTranslation } from "@gift/utils";
import { Button } from "../../../";
import {
    StyledContentContainer,
    StyledEventContainer,
    StyledHeader,
    StyledImage,
    StyledProgressBox,
    StyledText,
    StyledTitle,
} from "./EventPreview.styled";

export const EventPreview: React.FC<IEvent> = ({
    id,
    name,
    description,
    image,
    amount,
    currencyCode,
    contributions,
    dueDate,
    state,
}) => {
    const t = useTranslation();
    const navigateTo = useNavigation();

    return (
        <StyledEventContainer>
            <StyledTitle level={3} alignment="left">
                {name}
            </StyledTitle>

            <StyledHeader>
                <StyledContentContainer>
                    <StyledImage storagePath={image} />
                </StyledContentContainer>

                <StyledContentContainer>
                    <StyledProgressBox
                        amount={amount}
                        currencyCode={currencyCode}
                        contributions={contributions}
                        dueDate={dueDate}
                        state={state}
                        isCondensed
                        isDueDateShown={false}
                    />
                </StyledContentContainer>
            </StyledHeader>

            <StyledText>{description}</StyledText>

            <Button
                label={t("buttonLabel_showProject")}
                onClick={() => navigateTo.eventDetail(id)}
            />
        </StyledEventContainer>
    );
};
