import { IEvent } from "@gift/types";
import { calcCartItemsPricesSum, calcTemplateItemsAmount } from "@gift/utils";
import { formatCurrency } from "@honzachalupa/utils";
import { useMemo } from "react";
import {
    StyledItem,
    StyledItemsContainer,
    StyledItemsFooter,
    StyledPrice,
    StyledText,
} from "./OrderItemsList.styled";

export interface IProps {
    event: IEvent;
    orderItems: any[];
    sumPriceLabel: string;
}

export const OrderItemsList: React.FC<IProps> = ({
    event,
    orderItems,
    sumPriceLabel,
}) => {
    const { prices } = useMemo(
        () =>
            calcTemplateItemsAmount(
                event.items,
                event.amount,
                event.currencyCode,
            ),
        [event],
    );
    const sumPrice = useMemo(
        () => calcCartItemsPricesSum(orderItems, prices),
        [prices],
    );

    return (
        <div>
            <StyledItemsContainer>
                {orderItems.map(({ id, quantity, pricePerItem }) => {
                    if (quantity > 0) {
                        const { label } = event.items.find(
                            (item) => item.id === id,
                        )!;

                        return (
                            <StyledItem key={id}>
                                <p style={{ width: 300 }}>{label}</p>
                                <p style={{ width: 80 }}>Počet: {quantity}</p>
                                <p style={{ width: 100, textAlign: "right" }}>
                                    {formatCurrency(
                                        pricePerItem * quantity,
                                        event.currencyCode,
                                    )}
                                </p>
                            </StyledItem>
                        );
                    }

                    return null;
                })}
            </StyledItemsContainer>

            <StyledItemsFooter>
                <StyledText>{sumPriceLabel}</StyledText>

                <StyledPrice>
                    {formatCurrency(sumPrice, event.currencyCode)}
                </StyledPrice>
            </StyledItemsFooter>
        </div>
    );
};
