import { ButtonsGroup, Image, ProgressBox, Title } from "@gift/ui";
import styled from "styled-components";

export const StyledHeader = styled.header`
    margin: 2vh 0;
    position: relative;
`;

export const StyledTitle = styled(Title)`
    margin-top: 0;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        width: calc(100% - 200px);
    }
`;

export const StyledHeaderButtonsGroup = styled(ButtonsGroup)`
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        position: absolute;
        top: 10px;
        right: -8px;
    }
`;

export const StyledHeaderContent = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 2vh;
`;

export const StyledHeaderImage = styled(Image)`
    width: 60%;
    aspect-ratio: 16/10;
    object-fit: cover;
    box-shadow: 0 0 15px ${({ theme }) => theme.colors.grayLight};

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        width: calc(100% + 40px);
        margin: 0 -20px;
    }
`;

export const StyledHeaderContentContainerRight = styled.div`
    width: 40%;
    padding-left: 20px;

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        width: 100%;
        margin-top: 40px;
        padding-left: 0;
    }
`;

export const StyledProgressBox = styled(ProgressBox)`
    width: 100%;
`;
