import styled from "styled-components";
import { CopyButton, Text } from "../../../";

export const StyledContainer = styled.div`
    text-align: center;
`;

export const StyledText = styled(Text)`
    margin-top: 20px;
`;

export const StyledCopyButton = styled(CopyButton)`
    position: relative;
    top: 2px;
`;
