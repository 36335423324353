// import { PDFDownloadLink } from "@react-pdf/renderer";
import { IEvent, IOrder, IPayment } from "@gift/types";
import {
    Button,
    ButtonsGroup,
    Layout,
    OrderItemsList,
    PdfDocumentPreview,
    StyledInputLabel,
    TemplateItemCertificateDocument,
    Title,
} from "@gift/ui";
import {
    calcTemplateItemsAmount,
    convertBBANtoIBAN,
    EventActions,
    getPaymentUrl,
    log,
    PaymentActions,
    useCurrentPage,
    useTranslation,
} from "@gift/utils";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Form_Checkout } from "../../../components/organisms/forms/Checkout";
import { StyledCarousel } from "./Checkout.styled";

export const Page_EventCheckout: React.FC = () => {
    const t = useTranslation();
    const {
        navigateTo,
        location: { state },
        params: { id: eventId },
    } = useCurrentPage({ title: t("headline_contribute") });

    const [event, setEvent] = useState<IEvent>();
    const [formData, setFormData] = useState<IPayment["certificateData"]>();

    const order = state as IOrder;

    const { prices } = useMemo(
        () =>
            event
                ? calcTemplateItemsAmount(
                      event.items,
                      event.amount,
                      event.currencyCode,
                  )
                : {
                      prices: null,
                  },
        [event],
    );

    const handleSubmit = async () => {
        if (event && formData) {
            const paymentId = Math.round(Math.random() * 10000000);

            const paymentData = {
                amount: 1,
                iban: await convertBBANtoIBAN(event.bban),
                varsymbol: paymentId,
            };

            const paymentUrl = await getPaymentUrl(
                paymentData,
                formData.emailAddress,
            );

            PaymentActions.create(paymentId, {
                currencyCode: event.currencyCode,
                bban: event.bban,
                eventId: event.id,
                certificateData: {
                    name: formData.name,
                    emailAddress: formData.emailAddress,
                    message: formData.message,
                },
                items: order.items,
                ...paymentData,
            }).then(() => window.open(paymentUrl));
        }
    };

    useEffect(() => {
        if (!order) {
            navigateTo.eventDetail(eventId);
        }

        EventActions.get(eventId)
            .then(setEvent)
            .catch((error) => {
                log({
                    code: "EXCEPTION",
                    scope: Page_EventCheckout.name,
                    error,
                });
            });
    }, [eventId, order]);

    return event && prices ? (
        <Layout width="narrow">
            <Title level={1}>{t("headline_contributeCheckout")}</Title>

            <StyledInputLabel>
                {t("content_contributionItemsOverview")}
            </StyledInputLabel>

            <OrderItemsList
                event={event}
                orderItems={order.items}
                sumPriceLabel={t("content_contributionAmountSum")}
            />

            <Form_Checkout onChange={setFormData} />

            {formData && (
                <Fragment>
                    <StyledInputLabel>
                        {t("content_certificatePreview")}
                    </StyledInputLabel>

                    <StyledCarousel
                        isIndicatorsShown
                        slides={order.items.map((item) => ({
                            renderer: () => (
                                <PdfDocumentPreview
                                    document={
                                        <TemplateItemCertificateDocument
                                            event={event}
                                            item={item}
                                            formData={formData}
                                        />
                                    }
                                />
                            ),
                        }))}
                    />
                </Fragment>
            )}

            <div style={{ marginTop: 20 }}>
                <ButtonsGroup>
                    <Button
                        label={t("buttonLabel_pay")}
                        size="big"
                        color="accentSecondary"
                        onClick={handleSubmit}
                    />
                </ButtonsGroup>
            </div>
        </Layout>
    ) : null;
};
