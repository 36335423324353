// import { PDFDownloadLink } from "@react-pdf/renderer";
import { IFormInput } from "@gift/types";
import { Form } from "@gift/ui";
import { useTranslation } from "@gift/utils";
import { useMemo } from "react";

export interface IFormData {
    name: string;
    emailAddress: string;
    message: string;
}

export interface IProps {
    onChange: (formData: IFormData) => void;
}

export const Form_Checkout: React.FC<IProps> = ({ onChange }) => {
    const t = useTranslation();

    return (
        <Form
            inputs={useMemo<IFormInput[]>(
                () => [
                    {
                        id: "name",
                        label: t("inputLabel_contributorName"),
                        placeholder: t("inputPlaceholder_contributorName"),
                        controlType: "input",
                        rules: {
                            required: true,
                        },
                    },
                    {
                        id: "emailAddress",
                        label: t("inputLabel_yourEmailAddress"),
                        placeholder: t("inputPlaceholder_yourEmailAddress"),
                        controlType: "input",
                        rules: {
                            required: true,
                        },
                    },
                    {
                        id: "message",
                        label: t("inputLabel_messageContent"),
                        controlType: "input",
                    },
                ],
                [t],
            )}
            onChange={onChange}
        />
    );
};
