import { IContext, ICurrentRoute } from "@gift/types";
import { Helmet, Loader } from "@gift/ui";
import {
    Analytics,
    Authentication,
    config,
    initialContext,
    isProd,
    log,
    TranslationsActions,
    UsersActions,
} from "@gift/utils";
import {
    getBrowserLanguage,
    initServiceWorker,
    LANGUAGE_TEST,
    useLogRocket,
} from "@honzachalupa/utils";
import React, { useEffect, useState } from "react";
// import { Messaging } from "../helpers/firebase";
import { ProvidersWrapper } from "./ProvidersWrapper";
import { Router } from "./Router";

export const App: React.FC = () => {
    const [state, setState] = useState<IContext>({
        ...initialContext,
        language: getBrowserLanguage("cs"),
    });

    useLogRocket({
        token: config.logRocketToken,
        userId: state.user?.id,
        isEnabled: state.isCookiesAllowed,
    });

    const setCurrentRoute = (currentRoute: ICurrentRoute) => {
        setState((prevState: IContext) => ({
            ...prevState,
            currentRoute,
        }));
    };

    const setNavigationRef = (id: string, ref: any) => {
        setState((prevState: IContext) => ({
            ...prevState,
            navigationRefs: {
                ...prevState.navigationRefs,
                [id]: ref,
            },
        }));
    };

    const setIsScrollDisabled = (value: boolean) => {
        setState((prevState: IContext) => ({
            ...prevState,
            isScrollDisabled: value,
        }));
    };

    const setIsCookiesAllowed = (value: boolean) => {
        setState((prevState: IContext) => ({
            ...prevState,
            isCookiesAllowed: value,
        }));
    };

    useEffect(() => {
        console.info(
            "Version:",
            PROJECT_VERSION,
            "\nBuild timestamp:",
            PROJECT_BUILD_TIMESTAMP,
            "\nMode:",
            isProd ? "Production" : "Development",
        );

        TranslationsActions.search(state.language).then((translations) => {
            setState((prevState) => ({
                ...prevState,
                translations,
            }));
        });

        // Messaging.getToken();

        Authentication.onAuthStateChanged((user) => {
            if (user?.uid) {
                Analytics.setUserId(user.uid);

                UsersActions.get(user.uid)
                    .then((user) => {
                        setState((prevState: IContext) => ({
                            ...prevState,
                            user,
                        }));
                    })
                    .catch((error) => {
                        log({
                            code: "EXCEPTION",
                            scope: App.name,
                            error,
                        });
                    });
            } else {
                setState((prevState: IContext) => ({
                    ...prevState,
                    user: null,
                }));
            }
        });

        if (config.caching && isProd) {
            initServiceWorker();
        }
    }, []);

    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, [state.currentRoute]);

    const globalFunctions = {
        setCurrentRoute,
        setNavigationRef,
        setIsScrollDisabled,
        setIsCookiesAllowed,
    };

    const isLoading =
        state.user !== undefined &&
        (!!state.translations || state.language === LANGUAGE_TEST);

    return (
        <ProvidersWrapper context={{ ...state, ...globalFunctions }}>
            <Helmet />

            {isLoading ? <Router /> : <Loader />}
        </ProvidersWrapper>
    );
};
