import { IEvent } from "@gift/types";
import { ContentBlockHeader, EventPreview, Loader } from "@gift/ui";
import { EventActions } from "@gift/utils";
import { useEffect, useState } from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
    margin: 80px 0 40px 0;

    @media (min-width: 1500px) {
        margin-left: -10vw;
        margin-right: -10vw;
    }
`;

const StyledEventsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;
`;

export const ContentBlock_EventsPreview: React.FC = () => {
    const [events, setEvents] = useState<IEvent[]>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>();

    useEffect(() => {
        Promise.all([
            EventActions.get("ll43fFEuyqPg4EJlhslB"),
            EventActions.get("tySGrZiLzVvNa2kmYS75"),
            EventActions.get("V9L7p9dHdykDiJBmee5n"),
            EventActions.get("AxwNxf4DKGc3s162vqAO"),
        ])
            .then((responses) => responses.filter(Boolean))
            .then(setEvents)
            .finally(() => {
                setIsLoaded(true);
            });
    }, []);

    return isLoaded ? (
        <StyledContainer>
            <ContentBlockHeader headline="Přání která si právě teď plníte" />

            <StyledEventsContainer>
                {events.map((event) => (
                    <EventPreview key={event.id} {...event} />
                ))}
            </StyledEventsContainer>
        </StyledContainer>
    ) : (
        <Loader />
    );
};
