import { IContext } from "@gift/types";
import { LANGUAGE_TEST } from "@honzachalupa/utils";
import { useCallback, useContext } from "react";
import { Context } from "../helpers/context";
import { log } from "../helpers/logging";

export const useTranslation = () => {
    const { translations, language } = useContext<IContext>(Context);

    const getTranslation = useCallback(
        (key: string) => {
            const translation =
                translations && translations[key as unknown as string];

            if (translation) {
                return translation;
            } else {
                if (language !== LANGUAGE_TEST) {
                    log({
                        code: "TRANSLATION_NOT_FOUND",
                        scope: useTranslation.name,
                        error: new Error(
                            "Translation with given key doesn't exists.",
                        ),
                        data: {
                            translationKey: key,
                        },
                    });
                }

                return `{${key}}`;
            }
        },
        [translations],
    );

    return getTranslation;
};
