import styled from "styled-components";
import { Button, Text } from "../../../../";

export const StyledContainer = styled.div`
    background-color: #f6f3ea;
    margin-top: 120px;
    padding: 30px 0 20px 0;
`;

export const StyledCopyright = styled(Text)`
    font-size: 13px;
`;

export const StyledButton = styled(Button)`
    min-width: auto;
    color: ${({ theme }) => theme.fontColors.faded};
    font-size: 13px;
    font-weight: 400;
    text-transform: none;
    box-shadow: none;
    padding: 10px;
`;
