import { IModalRefProps, Modal } from "@gift/ui";
import { forwardRef, RefAttributes } from "react";
import { Form_PasswordReset } from "../forms/PasswordReset";

export interface IProps {
    emailAddress: string | undefined;
    onSubmit: () => void;
}

export const Modal_PasswordReset: React.FC<
    IProps & RefAttributes<IModalRefProps>
> = forwardRef(({ emailAddress, onSubmit }, ref) => {
    return (
        <Modal ref={ref} height={800}>
            <h1>Resetovat hesla</h1>

            <Form_PasswordReset
                emailAddress={emailAddress}
                onSubmit={onSubmit}
            />
        </Modal>
    );
});
