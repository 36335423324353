import { IMail } from "@gift/types";
import { Collections, Database } from "../helpers/firebase";

export const MailActions = {
    send: ({ from, to, subject, content }: IMail) =>
        Database.create(Collections.mailQueue, {
            to,
            replyTo: from,
            message: {
                subject: subject,
                text: content
                    .replace(/<[a-z\s]*\/[a-z]*>/gi, " ")
                    .replace(/\s{2,}/g, " "),
                html: content,
            },
        }),
};
