import styled from "styled-components";
import { Button, Icon } from "../../../../../../";
import { NavigationItem } from "../components/NavigationItem";

export const StyledContainer = styled.nav`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 38px 0;
`;

export const StyledNavigationLeft = styled.nav`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StyledNavigationRight = styled.nav`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const StyledNavigationItem = styled(NavigationItem)`
    color: ${({ theme }) => theme.fontColors.faded};
`;

export const StyledCreateEventNavigationItem = styled(NavigationItem)`
    background-color: ${({ theme }) => theme.colors.accentSecondary};
    color: ${({ theme }) => theme.fontColors.white};
    font-weight: 600;
    border-radius: 0;
    opacity: 1;
    padding: 12px 20px;
    margin-left: 10px;
`;

export const StyledIcon = styled(Icon)`
    width: 25px;
    height: 25px;
    background-color: ${({ theme }) => theme.colors.accentSecondary};
    border-radius: 50%;
    padding: 5px;
    margin-right: 12px;
`;

export const StyledButton = styled(Button)`
    margin-left: 20px;
`;
