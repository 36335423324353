import { administrationRoutes } from "@gift/admin";
import { Fragment } from "react";
import { BrowserRouter, Routes } from "react-router-dom";
import accountRoutes from "../pages/Account/Account.routes";
import authenticationRoutes from "../pages/Authentication/Authentication.routes";
import contactUsRoutes from "../pages/ContactUs/ContactUs.routes";
import eventRoutes from "../pages/Event/Event.routes";
import homeRoutes from "../pages/Home/Home.routes";
import notFoundRoutes from "../pages/NotFound/NotFound.routes";
import paymentRoutes from "../pages/Payment/Payment.routes";
import personalDataRoutes from "../pages/PersonalDataProtection/PersonalDataProtection.routes";
import termsAndConditionsRoutes from "../pages/TermsAndConditions/TermsAndConditions.routes";

export const Router: React.FC = () => {
    const routes = [
        ...homeRoutes,
        ...eventRoutes,
        ...paymentRoutes,
        ...authenticationRoutes,
        ...accountRoutes,
        ...contactUsRoutes,
        ...termsAndConditionsRoutes,
        ...personalDataRoutes,
        ...administrationRoutes,
        ...notFoundRoutes,
    ];

    return (
        <BrowserRouter>
            <Routes
                children={routes.map((route, i) => (
                    <Fragment key={i}>{route}</Fragment>
                ))}
            />
        </BrowserRouter>
    );
};
