import { ERoutePaths, IEvent } from "@gift/types";
import { useNavigation, useTranslation } from "@gift/utils";
import { Link, QrCode } from "../../../";
import {
    StyledContainer,
    StyledCopyButton,
    StyledText,
} from "./ShareEventBox.styled";

export interface IProps {
    eventId: IEvent["id"];
}

export const ShareEventBox: React.FC<IProps> = ({ eventId }) => {
    const t = useTranslation();
    const navigateTo = useNavigation();

    const url = `${window.location.origin}${ERoutePaths.EVENT_DETAIL.replace(
        ":id",
        eventId,
    )}`;

    return (
        <StyledContainer>
            <QrCode value={url} />

            <StyledText alignment="center">
                {t("content_shareLinkDescription")}:
            </StyledText>

            <Link label={url} onClick={() => navigateTo.eventDetail(eventId)} />

            <StyledCopyButton value={url} />
        </StyledContainer>
    );
};
