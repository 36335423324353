import { IContext } from "@gift/types";
import { ContentBlockGrid } from "@gift/ui";
import { Context } from "@gift/utils";
import { useContext, useEffect, useRef } from "react";

export const ContentBlock_FAQ: React.FC = () => {
    const ref = useRef<HTMLElement>(null);

    const { setNavigationRef } = useContext<IContext>(Context);

    useEffect(() => {
        setNavigationRef("faq", ref);
    }, []);

    return (
        <ContentBlockGrid
            ref={ref}
            subheadline="FAQ"
            headline="Nejčastější otázky"
            description="Chcete se dozvědět více? Napište nám prostřednictvím kontaktního formuláře."
            items={[
                {
                    label: "Je to zdarma?",
                    description:
                        "Založení je zdarma. Za využití služby účtujeme poplatek 4% z dosažené částky. Protože získáte oproti jiným metodám více, je pro vás služba vždy výhodnější.",
                },
                {
                    label: "Jsou vybrané peníze v bezpečí?",
                    description:
                        "Obavy můžete hodit za hlavu. Vybrané peníze putují prostřednictvím zabezpečeného převodu PSD2 Payment Initiation Service přímo na účet, který zadáte při vytvoření profilu.",
                },
                {
                    label: "Můžeme peníze použít na cokoli?",
                    description:
                        "Ano, k ničemu se nezavazujete a je zcela na vás, zda získané finance použijete k uvedenému účelu, nebo se nakonec rozhodnete jinak.",
                },
                {
                    label: "Můžeme si určit název a hodnotu jednotlivých položek?",
                    description:
                        "Ano. Stanovíte si celkový rozpočet a vytvoříte seznam položek. Vybíráte z přednastaveného seznamu, nebo si vytvoříte vlastní.",
                },
                {
                    label: "Jak hosté získají certifikát?",
                    description:
                        "Po platbě se certifikát automaticky vygeneruje a odešle na zadanou e-mailovou adresu. Stačí jen vytisknout a předat nevěstě a ženichovi.",
                },
            ]}
        />
    );
};
