import React, { forwardRef, RefAttributes } from "react";
import { useTheme } from "styled-components";
import { ContentBlockHeader, TIconName } from "../../../";
import {
    StyledContainer,
    StyledItem,
    StyledItemDescription,
    StyledItemIcon,
    StyledItemLabel,
    StyledItemsContainer,
} from "./ContentBlockRow.styled";

export interface IProps {
    subheadline?: string;
    headline: string;
    description?: string;
    backgroundColor?: string;
    items: {
        label?: string;
        description: string;
        iconName: TIconName;
    }[];
}

export const ContentBlockRow: React.FC<IProps & RefAttributes<HTMLElement>> =
    forwardRef(
        (
            { subheadline, headline, description, backgroundColor, items },
            ref,
        ) => {
            const theme = useTheme();

            return (
                <StyledContainer ref={ref} backgroundColor={backgroundColor}>
                    <ContentBlockHeader
                        subheadline={subheadline}
                        headline={headline}
                        description={description}
                    />

                    <StyledItemsContainer>
                        {items.map(({ label, description, iconName }) => (
                            <StyledItem
                                key={label + description}
                                columnsCount={items.length}
                            >
                                <StyledItemIcon
                                    name={iconName}
                                    color={theme.colors.accentSecondary}
                                    size={40}
                                />

                                {label && (
                                    <StyledItemLabel level={3}>
                                        {label}
                                    </StyledItemLabel>
                                )}

                                <StyledItemDescription>
                                    {description}
                                </StyledItemDescription>
                            </StyledItem>
                        ))}
                    </StyledItemsContainer>
                </StyledContainer>
            );
        },
    );
