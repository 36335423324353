import { FirebaseConnector, Firestore } from "@honzachalupa/firebase";
import credentialsDev from "../firebase.dev.json";
import credentialsProd from "../firebase.prod.json";
import { log } from "../helpers/logging";
import { isDev } from "./app";

const credentials = isDev ? credentialsDev : credentialsProd;

const { firestore, ...connector } = new FirebaseConnector(credentials, log);

export const where = Firestore.where;
export const limit = Firestore.limit;
export const orderBy = Firestore.orderBy;
export const Authentication = connector.Authentication;
export const Database = connector.Database;
export const Storage = connector.Storage;
export const Analytics = connector.Analytics;

export const Collections = {
    translations: Firestore.collection(firestore, "config_translations"),
    templates: Firestore.collection(firestore, "config_templates"),
    users: Firestore.collection(firestore, "users"),
    events: Firestore.collection(firestore, "events"),
    payments: Firestore.collection(firestore, "payments"),
    invoices: Firestore.collection(firestore, "invoices"),
    logs: Firestore.collection(firestore, "logs"),
    mailQueue: Firestore.collection(firestore, "mail-queue"),
    messagingTokens: Firestore.collection(firestore, "messaging-tokens"),
};
