import { ContentBlockGrid } from "@gift/ui";

export const ContentBlock_9: React.FC = () => (
    <ContentBlockGrid
        headline="Velká přání se plní, když je rozdělíte na malé kousky"
        columns={3}
        items={[
            {
                label: "Rychlé založení",
                description: "a snadné nastavení",
                iconName: "speed",
            },
            {
                label: "100% bezpečnost",
                description: "vybraných peněz",
                iconName: "shield",
            },
            {
                label: "Naprosto diskrétní",
                description: "pro jednotlivé hosty",
                iconName: "person",
            },
        ]}
    />
);
