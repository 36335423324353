import { IConfigTemplate } from "@gift/types";
import { convertDocsToItems, TQuery } from "@honzachalupa/firebase";
import { Collections, Database } from "../helpers/firebase";
import { log } from "../helpers/logging";

export const TemplatesActions = {
    search: (query?: TQuery) =>
        Database.search(Collections.templates, query)
            .then((docs) =>
                (convertDocsToItems(docs) as IConfigTemplate[]).map(
                    // Add "availableQuantity" prop
                    (template) => ({
                        ...template,
                        items: template.items.map((item) => ({
                            ...item,
                            availableQuantity: item.quantity,
                        })),
                    }),
                ),
            )
            .catch((error) => {
                log({
                    code: "EXCEPTION",
                    scope: "TemplatesActions",
                    error,
                });

                return [];
            }) as Promise<IConfigTemplate[]>,
};
