import { Button, ButtonsGroup, IModalRefProps, Modal } from "@gift/ui";
import { useLocalStorage } from "@honzachalupa/utils";
import { forwardRef, RefAttributes, useEffect } from "react";
import { ContentBlock_HowItWorksDetail } from "../content-blocks/HowItWorksDetail";

export const Modal_HowItWorks: React.FC<RefAttributes<IModalRefProps>> =
    forwardRef((_, ref) => {
        const [isOpenedLS, setIsOpenedLS] = useLocalStorage(
            "shouldHowItWorksModalBeShown",
            true,
        );

        useEffect(() => {
            if (isOpenedLS) {
                // @ts-ignore
                ref.current?.show();
            }
        }, [isOpenedLS]);

        return (
            <Modal ref={ref} width={1200} onClose={() => setIsOpenedLS(false)}>
                <ContentBlock_HowItWorksDetail />

                <ButtonsGroup>
                    <Button
                        label="Jdeme na to!"
                        color="accentPrimary"
                        size="big"
                        onClick={() => {
                            // @ts-ignore
                            ref.current?.hide();

                            setIsOpenedLS(false);
                        }}
                    />
                </ButtonsGroup>
            </Modal>
        );
    });
