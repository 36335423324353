import { IEvent } from "@gift/types";
import { Button, Layout, ShareEventBox, Title } from "@gift/ui";
import { EventActions, log, useCurrentPage, useTranslation } from "@gift/utils";
import { Fragment, useEffect, useState } from "react";
import { StyledButtonsGroup } from "./Summary.styled";

export const Page_EventSummary: React.FC = () => {
    const {
        params: { id: eventId },
        navigateTo,
    } = useCurrentPage({ title: "Vytvoření přání" });
    const t = useTranslation();

    const [event, setEvent] = useState<IEvent>();

    useEffect(() => {
        EventActions.get(eventId)
            .then(setEvent)
            .catch((error) => {
                log({
                    code: "EXCEPTION",
                    scope: Page_EventSummary.name,
                    error,
                });
            });
    }, [eventId]);

    return (
        <Layout width="narrow">
            <Title level={1}>{t("headline_createProjectSuccess")}</Title>

            {event && (
                <Fragment>
                    <ShareEventBox eventId={event.id} />

                    <StyledButtonsGroup>
                        <Button
                            label={t("buttonLabel_showProject")}
                            color="blue"
                            size="big"
                            onClick={() => navigateTo.eventDetail(event.id)}
                        />
                    </StyledButtonsGroup>
                </Fragment>
            )}
        </Layout>
    );
};
