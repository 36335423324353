import { EPaymentStates, IEvent, IPayment } from "@gift/types";
import {
    Layout,
    Loader,
    OrderItemsList,
    PdfDocumentsList,
    StyledInputLabel,
    Text,
    Title,
} from "@gift/ui";
import {
    EventActions,
    log,
    MailActions,
    MailTemplates,
    PaymentActions,
    useCurrentPage,
    useTranslation,
} from "@gift/utils";
import { fillStringVariables } from "@honzachalupa/utils";
import React, { useEffect, useState } from "react";

export const Page_PaymentConfirmation: React.FC = () => {
    const t = useTranslation();
    useCurrentPage({
        title: "Potvrzení platby",
    });

    const [event, setEvent] = useState<IEvent>();
    const [payment, setPayment] = useState<IPayment>();

    const params = new URL(window.location.href).searchParams;
    const paymentId = Number(params.get("varsymbol"));
    const isSuccess = params.get("completed") === "Y";
    const isLoaded = payment && event;

    const paymentState = isSuccess
        ? EPaymentStates.SUCCESS
        : EPaymentStates.FAILED;

    const sendMail = (to: string) => {
        MailActions.send(
            MailTemplates.paymentConfirmation({ to, paymentId }),
        ).then(setIsCertificatesSent);
    };

    const setIsCertificatesSent = () => {
        PaymentActions.setIsCertificatesSent(paymentId).catch(() => {
            log({
                code: "EXCEPTION",
                scope: Page_PaymentConfirmation.name,
                error: new Error("Unable to update payment."),
                data: {
                    id: paymentId,
                },
            });
        });
    };

    const updatePaymentState = () => {
        PaymentActions.updateState(paymentId, paymentState).catch(() => {
            log({
                code: "EXCEPTION",
                scope: Page_PaymentConfirmation.name,
                error: new Error("Unable to update payment state."),
                data: {
                    id: paymentId,
                },
            });
        });
    };

    const getEventData = (eventId: string) => {
        EventActions.get(eventId)
            .then(setEvent)
            .catch(() => {
                log({
                    code: "EXCEPTION",
                    scope: Page_PaymentConfirmation.name,
                    error: new Error("Event not found."),
                    data: {
                        payment,
                    },
                });
            });
    };

    const getPaymentData = () => {
        PaymentActions.get(paymentId)
            .then((payment) => {
                setPayment(payment);
                getEventData(payment.eventId);

                if (
                    paymentState === EPaymentStates.SUCCESS &&
                    !payment.isCertificatesSent
                ) {
                    sendMail(payment.certificateData.emailAddress);
                }
            })
            .catch(() => {
                log({
                    code: "EXCEPTION",
                    scope: Page_PaymentConfirmation.name,
                    error: new Error("Payment not found."),
                    data: {
                        id: paymentId,
                    },
                });
            });
    };

    useEffect(() => {
        updatePaymentState();
        getPaymentData();
    }, []);

    return (
        <Layout width="narrow">
            {isLoaded ? (
                paymentState === EPaymentStates.SUCCESS ? (
                    <div>
                        <Title level={1}>Platba proběhla úspěšně</Title>

                        <Text alignment="center">
                            {fillStringVariables(
                                "Certifikáty jsme vám zaslali na e-mailovou adresu {emailAddress}.",
                                {
                                    emailAddress:
                                        payment.certificateData.emailAddress,
                                },
                            )}
                        </Text>

                        <StyledInputLabel>
                            {t("content_contributionItemsOverview")}
                        </StyledInputLabel>

                        <OrderItemsList
                            event={event}
                            orderItems={payment.items}
                            sumPriceLabel="Celkem"
                        />

                        <StyledInputLabel>
                            {t("content_certificatePreview")}
                        </StyledInputLabel>

                        <PdfDocumentsList event={event} payment={payment} />
                    </div>
                ) : paymentState === EPaymentStates.FAILED ? (
                    <div>
                        <Title level={3}>Platba proběhla neúspěšně</Title>
                    </div>
                ) : null
            ) : (
                <Loader />
            )}
        </Layout>
    );
};
