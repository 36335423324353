import { ERoutePaths } from "@gift/types";
import { Route } from "react-router-dom";
import { Page_CertificatesDownload } from "./CertificatesDownload";
import { Page_PaymentConfirmation } from "./Confirmation";

export default [
    <Route
        path={ERoutePaths.PAYMENT_CONFIRMATION}
        element={<Page_PaymentConfirmation />}
    />,
    <Route
        path={ERoutePaths.PAYMENT_CERTIFICATES_DOWNLOAD}
        element={<Page_CertificatesDownload />}
    />,
];
