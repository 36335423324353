import { Layout, Text, Title } from "@gift/ui";
import { useCurrentPage, useTranslation } from "@gift/utils";

export const Page_TermsAndConditions: React.FC = () => {
    const t = useTranslation();
    useCurrentPage({
        title: t("buttonLabel_termsAndConditions"),
    });

    return (
        <Layout>
            <Title level={1}>{t("buttonLabel_termsAndConditions")}</Title>

            <Title level={3}>Preambule</Title>

            <Text>
                Tyto darovací podmínky (dále jako „Darovací podmínky“) upravují
                podmínky pro založení projektové sbírky v rámci projektu
                DarekNaSvatbu.cz ve prospěch Novomanželů (dále jako „Sbírka na
                líbánky“), práva a povinnosti vzniklé na základě uzavřené
                Darovací smlouvy mezi Dárcem a BrightWorks s.r.o. jakožto
                zprostředkovatelem peněžního daru pro Obdarovaného.
            </Text>

            <Title level={3}>I. Definice</Title>

            <Text>
                Bankovním účtem se v těchto Darovacích podmínkách rozumí
                bankovní novomanžele nebo novomanželky.
                <br />
                Dárcem se v těchto Darovacích podmínkách rozumí fyzická osoba
                pozvaná na svatbu.
                <br />
                Obdarovaným se v těchto Darovacích podmínkách rozumí novomanžel
                a novomanželka.
                <br />
                Poplatkem se v těchto Darovacích podmínkách rozumí poplatek na
                úhradu nákladů spojených s konáním sbírky ve výši 4% z obdržené
                částky.
                <br />
                Webovými stránkami se v těchto Darovacích podmínkách rozumí
                internetové stránky www.DarekNaSvatbu.cz
            </Text>

            <Title level={3}>I. Založení sbírky na líbánky</Title>

            <Text>
                <ol>
                    <li>
                        Pro založení Projektové sbírky je nezbytné vyplnit
                        formulář o žádosti o založení Projektové sbírky
                        Žadatelem, přičemž tento formulář je uveřejněn na
                        Webových stránkách.
                    </li>
                    <li>
                        Žadatel se zavazuje mít zajištěno případné poskytnutí
                        licenčního oprávnění k audiovizuálnímu dílu nahranému do
                        žádosti o založení projektové sbírky, a to ve smyslu
                        ustanovení § 12 odst. 1 zákona č. 121/2000 Sb., zákon o
                        právu autorském, o právech souvisejících s právem
                        autorským a o změně některých zákonů (autorský zákon).
                    </li>
                    <li>
                        Žadatel je povinen při odesílání formuláře o žádosti o
                        založení Projektové sbírky vyslovit svůj souhlas s
                        čestným prohlášením ve znění podle osoby Žadatele
                        (fyzická osoba, právnická osoba), Souhlas se zpracováním
                        zvláštních osobních údajů a potvrzení o seznámení se se
                        zásadami zpracování osobních údajů, Darovacími
                        podmínkami, Licenčním ujednáním.
                    </li>
                </ol>
            </Text>

            <Title level={3}>I. Poskytování darů Dárci</Title>

            <Text>
                <ol>
                    <li>
                        Po založení Projektové sbírky bude na Webových stránkách
                        uveřejněn účel konání Projektové sbírky, délka trvání
                        Projektové sbírky, Bankovní účet, na nějž mohou Dárci
                        zasílat své dary.
                    </li>
                    <li>
                        Finanční dary mohou Dárci zasílat prostřednictvím těchto
                        platebních metod: převodem z účtu.
                    </li>
                </ol>
            </Text>
        </Layout>
    );
};
