import { IContext, IEvent, IOrder } from "@gift/types";
import { calcTemplateItemsAmount, Context, Storage } from "@gift/utils";
import { PdfRenderer } from "@honzachalupa/ui";
import { fillStringVariables, formatCurrency } from "@honzachalupa/utils";
import { useContext, useEffect, useMemo, useState } from "react";
import styles from "./TemplateItemCertificateDocument.styled";

PdfRenderer.Font.register(
    /*
        How to use Google Fonts with react-pdf?

        Get Google Fonts URLs with curl:
        `curl https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600&family=Josefin+Sans:wght@100;200;300;400;500;600;700&family=Playfair+Display:ital,wght@1,400;1,500;1,600&display=swap`

        Extract all URLs:
        from: `src: url(https://fonts.gstatic.com/s/josefinsans/v20/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_GbQXME.ttf) format('truetype');`
        to: https://fonts.gstatic.com/s/josefinsans/v20/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_GbQXME.ttf
    */

    {
        family: "Barlow",
        fonts: [
            {
                src: "https://fonts.gstatic.com/s/barlow/v5/7cHpv4kjgoGqM7EPCw.ttf",
                fontWeight: 400,
            },
        ],
    },
);

export interface IProps {
    event: IEvent;
    item: IOrder["items"][0];
    formData: {
        name: string;
        message?: string;
        emailAddress: string;
    };
}

export const TemplateItemCertificateDocument: React.FC<IProps> = ({
    event,
    item,
    formData,
}) => {
    const { language } = useContext<IContext>(Context);

    const [imageUrl, setImageUrl] = useState<string>();

    const { prices } = useMemo(
        () =>
            calcTemplateItemsAmount(
                event.items,
                event.amount,
                event.currencyCode,
            ),
        [],
    );
    const { label } = useMemo(
        () => event.items.find(({ id }) => item.id === id)!,
        [event],
    );
    const imagePath = useMemo(
        () => event.items.find(({ id }) => id === item.id)!.image,
        [event],
    );
    const { value: price } = useMemo(
        () => prices.find(({ id }) => item.id === id)!,
        [prices],
    );

    useEffect(() => {
        Storage.download(imagePath).then(setImageUrl);
    }, [imagePath]);

    return imageUrl ? (
        <PdfRenderer.Document language={language}>
            <PdfRenderer.Page
                size="A4"
                orientation="landscape"
                style={styles.page}
            >
                <PdfRenderer.View style={styles.section}>
                    <PdfRenderer.Text style={styles.eventName}>
                        {event.name}
                    </PdfRenderer.Text>

                    <PdfRenderer.Image src={imageUrl} style={styles.image} />

                    <PdfRenderer.Text style={styles.mainText}>
                        {fillStringVariables(
                            "Vaše přání {itemLabel} v hodnotě {itemPrice} se vám vyplní díky {contributorName}.",
                            {
                                itemLabel: label,
                                itemPrice: formatCurrency(
                                    price * item.quantity,
                                    event.currencyCode,
                                ),
                                contributorName: formData.name,
                            },
                        )}
                    </PdfRenderer.Text>

                    {formData.message ? (
                        <PdfRenderer.Text style={styles.messageText}>
                            {`Zpráva: ${formData.message}`}
                        </PdfRenderer.Text>
                    ) : null}
                </PdfRenderer.View>
            </PdfRenderer.Page>
        </PdfRenderer.Document>
    ) : null;
};
